import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import TextField from '../../../components/text-field';
import Button from '../../../components/button';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_SPECIAL } from '../../../utils/regexp';
// styles
import { Content, FormContent, Box, Message, ButtonWrapper } from '../../../styles';
// apis
import { createPotentialGroups, updatePotentialGroups } from '../../../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  name: yup.string()
    .trim()
    .required()
    .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
    .min(2, '2 - 30자 이내로 입력해주세요.')
    .max(30, '2 - 30자 이내로 입력해주세요.'),
  description: yup.string()
    .trim()
    .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
    .matches(/^.{2,30}$/, {message: '2 - 30자 이내로 입력해주세요.', excludeEmptyString: true}),
  latitude: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(-90, '-90 ~ 90 범위 내에서 입력해주세요.')
    .max(90, '-90 ~ 90 범위 내에서 입력해주세요.'),
  longitude: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(-180, '-180 ~ 180 범위 내에서 입력해주세요.')
    .max(180, '180 ~ 180 범위 내에서 입력해주세요.'),
  zoom: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(1, '1 ~ 21 범위 내에서 입력해주세요.')
    .max(21, '1 ~ 21 범위 내에서 입력해주세요.'),
  gridX: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(50, '50 ~ 140 범위 내에서 입력해주세요.')
    .max(140, '50 ~ 140 범위 내에서 입력해주세요.'),
  gridY: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(50, '50 ~ 140 범위 내에서 입력해주세요.')
    .max(140, '50 ~ 140 범위 내에서 입력해주세요.')
});

// ----------------------------------------------------------------------

export default function PotentialGroupFormPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const pathname = useLocation().pathname.split('/')[3];
  const create = pathname === 'create' ? true : false;
  const title = create ? '등록' : '수정';

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: state?.name || '',
      description: state?.description ? state.description : '',
      latitude: state?.latitude || 0,
      longitude: state?.longitude || 0,
      zoom: state?.zoom || 1,
      gridX: state?.gridX || 50,
      gridY: state?.gridY || 50
    }
  });

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    if ( !create && !state ) return navigate(-1);
    // eslint-disable-next-line
  }, [create]);

  const onSubmit = async (data) => {
    if ( !data.description || data.description.length === 0 ) delete data.description;
    if ( create ) {
      await createPotentialGroups(data)
        .then(() => {
          navigate('/system/potential-group', {state: {submit: true}});
        })
        .catch(() => {
          setMessage('부식 전위 그룹을 등록할 수 없습니다. 나중에 다시 시도해 주세요.');
        });
    } else {
      await updatePotentialGroups(state.id, data)
        .then(() => {
          navigate('/system/potential-group', {state: {submit: true}});
        })
        .catch(() => {
          setMessage('부식 전위 그룹을 수정할 수 없습니다. 나중에 다시 시도해 주세요.');
        });
    }
  };

  return (
    <Content>
      <Breadcrumbs
        heading={`부식 전위 그룹 ${title}`}
        links={[
          { name: '시스템 관리' },
          { name: '부식 전위 그룹 관리', link: '/system/potential-group' },
          { name: `부식 전위 그룹 ${title}` },
        ]}
      />

      <FormContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>

            <TextField
              type='text'
              label='그룹명'
              name='name'
              control={control}
            />

            <TextField
              type='text'
              label='설명'
              name='description'
              control={control}
            />

            <TextField
              type='text'
              label='위도'
              name='latitude'
              control={control}
            />

            <TextField
              type='text'
              label='경도'
              name='longitude'
              control={control}
            />

            <TextField
              type='text'
              label='줌 레벨'
              name='zoom'
              control={control}
            />

            <TextField
              type='text'
              label='격자 X'
              name='gridX'
              control={control}
            />

            <TextField
              type='text'
              label='격자 Y'
              name='gridY'
              control={control}
            />

            { message &&
              <Message>{ message }</Message>
            }

            <ButtonWrapper>
              <Button label={title} disabled={Object.keys(errors).length > 0 ? true : false}  />
              <button type='button' onClick={() => navigate(-1)}>취소</button>
            </ButtonWrapper>
          </form>
        </Box>
      </FormContent>
    </Content>
  );
}