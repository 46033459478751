import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Wrapper = styled.div`
  width: 430px;
  max-height: calc(100% - 40px);
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 30px 0 30px 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 115, 129, 0.3);
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  },
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ${({theme}) => theme.media.xs`
    width: 95%;
  `};
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: ${(props) => props.$margin ? '0' : '15px' };
`;

export const Content = styled.div`
  padding: 30px 0;

  > p {
    text-align: center;
    font-size: 17px;
  }

  > span {
    display: block;
    width: 100%;
    text-align: center;
    color: #637381;
    margin-bottom: 10px;
  }

  .dx-datagrid {
    margin-top: ${(props) => props.$type ? '0' : '20px' };
  }
`;

export const Icon = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(/assets/dialog/${(props) => props.$name }.png);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto 10px;
`;

export const Message = styled.p`
  font-size: 13px;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.error };
  height: 20px
`;

export const Actions = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.border };
  margin-top: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: center;

  button {
    width: 95px;
    height: 38px;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: ${(props) => props.theme.colors.primary.main };
  }
  button:disabled {
    color: #8F8F8F;
    background-color: #DDDDDD;
  }

  div {
    display: flex;
    justify-content: center;

    button:last-child {
      margin-left: 10px;
      background-color: #FFFFFF;
      color: #637381;
      border: 1px solid ${(props) => props.theme.colors.border };
    }
  }
`;