import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateSoilSensors = async (sensorId, data) => {
  return await axios.put(`soil-sensors/${sensorId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateSoilSensors;