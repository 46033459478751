import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deleteSoils = async (companyId, groupId) => {
  return await axios.delete(`company-soils/companies/${companyId}/groups/${groupId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deleteSoils;