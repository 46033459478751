import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link, Outlet } from 'react-router-dom';
// store
import { useSelector } from '../../store';
// sections
import SearchGroups from './SearchGroups';
// components
import Loaders from '../../components/loaders';
import { Dialog } from '../../components/dialog';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// hooks
import useResponsive from '../../hooks/useResponsive';
// styles
import { Header, Logo, AccountButton, AccountPopover, Main, MainContent, GnbButton, Gnb, GnbTop, Nav, AccordianMenu } from './styles';
// apis
import { getAccounts, logout } from '../../apis';

// ----------------------------------------------------------------------

const config = {
  soil: [{
    id: 'soil',
    title: '토양',
    children: [
      { path: '/dashboard', title: '센서 현황' },
      { path: '/analysis', title: '센서 데이터' },
      { path: '/infomation', title: '현장 설치 정보' }
    ]
  }],
  potential: [{
    id: 'potential',
    title: '부식 전위',
    children: [
      { path: '/dashboard', title: '센서 현황' },
      { path: '/analysis', title: '센서 데이터' },
      { path: '/infomation', title: '현장 설치 정보' }
    ]
  }],
  admin: [{
    id: 'manage',
    title: '운영 관리',
    children: [
      { path: '/login-log', title: '로그인 기록' }
    ]
  }, {
    id: 'system',
    title: '시스템 관리',
    children: [
      { path: '/company', title: '기업 관리' },
      { path: '/member', title: '사용자 관리' },
      { path: '/soil-group', title: '토양 그룹 관리' },
      { path: '/potential-group', title: '부식 전위 그룹 관리' }
    ]
  }]
};

// ----------------------------------------------------------------------

export default function Layout() {
  const isDesktop = useResponsive('up', 'md');

  const navigate  = useNavigate();

  const location = useLocation();
  const pathname = location.pathname.split('/')[1];
  const pathname2 = location.pathname.split('/')[2];

  const loading = useSelector(state => state.loading);
  const account = useSelector(state => state.account);
  const admin = account?.role === '관리자' ? true : false;

  const [ permission, setPermission ] = useState({ soil: false, potential: false });

  const [ visible, setVisible ] = useState(false);
  
  const [ error, setError ] = useState(false);

  const [ active, setActive ] = useState( isDesktop ? true : false );

  useEffect(() => {
    if ( !account ) {
      ( async () =>
        await getAccounts()
      )()
    } else {
      if ( account.permission === 0 ) return setPermission({ soil: false, potential: false });
      setPermission({ soil: account.permission !== 2 ? true : false, potential: account.permission !== 1 ? true : false });
    }
  }, [account]);

  useEffect(() => {
    if ( location.state?.submit ) {
      const handleEvent = () => window.history.pushState(null, '', location.href);
      window.history.pushState( null, '', location.href );
      window.addEventListener('popstate', handleEvent);
      return () => window.removeEventListener('popstate', handleEvent);
    }
    // eslint-disable-next-line
  }, [location.state]);

  useEffect(() => {
    if ( account ) {
      if (
        ( pathname === 'soil' && (account.permission === 2 || account.permission === 0) ) ||
        ( pathname === 'potential' && (account.permission === 1 || account.permission === 0) ) ||
        ( (pathname === 'manage' || pathname === 'system') && account.role !== '관리자' )
      ) {
        return navigate(-1);
      }
    }
    // eslint-disable-next-line
  }, [pathname, account]);

  const handleLogout = async () => {
    await logout()
      .catch(() => setError(true));
  };

  if ( !account ) return;

  return (
    <>
      { loading &&
        <Loaders />
      }

      <Header>
        <div>
          <GnbButton onClick={() => setActive(!active)} />
          <Logo />
        </div>

        { isDesktop &&
          <div>
            <AccountButton id='account' onClick={() => setVisible(true)}>{ account?.name } 님<div /></AccountButton>
            <Popover
              target='#account'
              visible={visible}
              hideOnOutsideClick={true}
              onHiding={() => setVisible(false)}
            >
              <AccountPopover>
                <p>{ account?.affiliation }</p>
                <Link to='/account' onClick={() => setVisible(false)}>
                  <div />계정 관리
                </Link>
                <button onClick={handleLogout}>
                  <div />로그아웃
                </button>
              </AccountPopover>
            </Popover>
          </div>
        }
      </Header>

      <Main $active={active}>
        <Gnb>
          { !isDesktop &&
            <GnbTop>
              <p>{ account?.name } 님<br /><span>{ account?.affiliation }</span></p>
              <div>
                <button onClick={() => {
                  navigate('account');
                  setActive(false);
                }} />
                <button onClick={handleLogout} />
                <div />
                <button onClick={() => setActive(false)} />
              </div>
            </GnbTop>
          }

          { ((permission.soil && pathname === 'soil') || (permission.potential && pathname === 'potential')) &&
            <SearchGroups permission={permission} pathname={pathname} setActive={setActive}  />
          }

          <Nav>
            <div>
              { permission.soil && config.soil.map((nav) => (
                <Accordian key={nav.id} nav={nav} pathname={pathname} pathname2={pathname2} setActive={setActive} isDesktop={isDesktop} />
              ))}
              { permission.potential && config.potential.map((nav) => (
                <Accordian key={nav.id} nav={nav} pathname={pathname} pathname2={pathname2} setActive={setActive} isDesktop={isDesktop} />
              ))}
              { isDesktop && admin && config.admin.map((nav) => (
                <Accordian key={nav.id} nav={nav} pathname={pathname} pathname2={pathname2} setActive={setActive} isDesktop={isDesktop} />
              ))}
            </div>
          </Nav>
        </Gnb>

        <MainContent>
          <Outlet />
        </MainContent>
      </Main>

      { error &&
        <Dialog
          actions={
            <button onClick={() => setError(false)}>
              확인
            </button>
          }
        >
          <p>
            로그아웃할 수 없습니다.<br />
            나중에 다시 시도해 주세요.
          </p>
        </Dialog>
      }
    </>
  );
}

function Accordian({ nav, pathname, pathname2, setActive, isDesktop }) {
  const [ visible, setVisible ] = useState(true);

  useEffect(() => {
    if ( nav.id === 'manage' || nav.id === 'system' ) setVisible(false);
  }, [ nav ]);

  return (
    <AccordianMenu $active={pathname === nav.id ? true : false} $visible={visible}>
      <button onClick={() => setVisible(!visible)}>{ nav.title }<div /></button>
      <div>
        { nav.children.map((item) => (
          <Link
            key={item.path}
            to={nav.id + item.path}
            className={`${pathname}/${pathname2}` === nav.id + item.path ? 'active' : ''}
            onClick={() => !isDesktop && setActive(false)}
          >
            { item.title }
          </Link>
        ))}
      </div>
    </AccordianMenu>
  );
}