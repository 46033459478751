import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

export const Header = styled.header`
  width: 100%;
  height: 70px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1002;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  ${({theme}) => theme.media.md`
    padding: 0 10px;
  `};
`;

export const GnbButton = styled.button`
  width: 38px;
  height: 38px;
  background-image: url(/assets/nav/gnb.svg);
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;

  &:hover {
    background-color: #F1F3F6;
  }
`;

export const Logo = styled.div`
  width: 124px;
  height: 45px;
  background-image: url(/assets/logo/original.svg);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 10px;
`;

export const AccountButton = styled.button`
  height: 38px;
  background-image: url(/assets/nav/user.svg);
  background-size: 18px 20px;
  background-position: left 18px center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 0 10px 0 48px;
  font-size: 15px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;

  div {
    width: 24px;
    height: 24px;
    background-image: url(/assets/nav/arrow.svg);
    background-size: 100% auto;
    background-position: right center;
    background-repeat: no-repeat;
    margin-left: 8px;
  }

  &:hover {
    background-color: #F1F1F1;
  }
`;

export const AccountPopover = styled.div`
  p {
    font-size: 15px;
    color: #666666;
    padding: 16px 20px;
    border-bottom: 1px solid #E8E8E8;
    font-weight: 500;
    text-align: center;
  }

  button, a {
    font-size: 15px;
    color: #8CA0AE;
    padding: 16px 20px;
    display: flex;
    align-items: center;
  }

  a > div,
  button > div {
    width: 24px;
    height: 24px;
    background-image: url(/assets/nav/account.svg);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
  button > div {
    background-image: url(/assets/nav/logout.svg);
  }
  a:hover,
  button:hover {
    color: #8CA0AE;
    background-color: #F1F3F6;
  }
`;

export const Main = styled.main`
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  padding-left: 100px;
  transition: all .2s ease-in-out;

  ${(props) => props.$active && css`
    padding-left: 280px;

    ${Gnb} {
      width: 280px;
    }

    ${Nav} {
      padding: 4px;

      button, a {
        font-size: 15px;
      }

      button {
        padding: 0 15px;

        div {
          width: 24px;
          height: 24px;
        }
      }

      a {
        padding-left: 35px;
    
        &::before {
          left: 15px;
        }
      }
    }

    ${SelectButton} {
      margin: 20px 10px;

      p {
        font-size: 15px;
      }
    
      button {
        padding: 0 15px;
        font-size: 15px;

        div {
          width: 24px;
          height: 24px;
        }
      }
    }
  `}

  ${(props) => props.$active && props.theme.media.md`
    ${Gnb} {
      width: 100%;
    }
  `};

  ${({theme}) => theme.media.md`
    padding-left: 0;
  `};
`;

export const MainContent = styled.div`
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Gnb = styled.div`
  width: 100px;
  height: 100%;
  border-right: 1px dashed #DDDDDD;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all .2s ease-in-out;
  z-index: 1001;
  overflow: hidden;

  ${({theme}) => theme.media.md`
    width: 0;
    border-right: none;
  `};
`;

export const Nav = styled.div`
  height: calc(100% - 104px);
  padding: 0;

  > div {
    height: 100%;
    padding: 6px 0px 6px 6px;
    overflow-y: scroll;
  
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(99, 115, 129, 0.3);
      border-radius: 10px;
      border: 8px solid transparent;
    },
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ${({theme}) => theme.media.sm`
      height: calc(100% - 45px);
    `};
  }

  button, a {
    width: 100%;
    height: 45px;
    font-size: 12px;
    display: block;
    font-weight: 500;
    width: 100%;
    text-align: left;
    margin: 2px 0;
    border-radius: 10px;
  }

  a {
    color: #666666;
    line-height: 45px;
    padding-left: 12px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #919EAB;
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translate(0,-50%); 
    }

    &:hover {
      background-color: #EBF8F2;
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 3px;

    div {
      width: 18px;
      height: 18px;
      background-image: url(/assets/nav/arrow.svg);
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:hover {
      background-color: #EBF8F2;
    }
  }
`;

export const SelectButton = styled.div`
  margin: 20px 6px;

  p {
    font-size: 12px;
    padding: 0 0 4px 4px;
    font-weight: 500;
  }

  button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: #F5F5F5;
    padding: 0 0 0 3px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    span {
      width: calc(100% - 18px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    div {
      width: 18px;
      height: 18px;
      background-image: url(/assets/nav/arrow.svg);
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  
    &:hover {
      background-color: #F1F1F1;
    }
  }
`;

export const GroupsPopover = styled.div`
  p {
    padding: 10px 15px 0;
    font-weight: 600;
    color: #009C4E;
    font-size: 13px;
  }
`;

export const GnbTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin: 20px 10px;


  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;

    span {
      color: #666666;
      font-size: 14px;
    }
  }

  > div {
    display: flex;
    align-items: center;

    div {
      width: 1px;
      height: 13px;
      margin: 0 16px;
      background-color: #AAAAAA;
    }

    button {
      width: 24px;
      height: 24px;
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
      &:nth-child(1) {
        background-image: url(/assets/nav/account.svg);
        margin-right: 16px;
      }
      &:nth-child(2) {
        background-image: url(/assets/nav/logout.svg);
      }
      &:nth-child(4) {
        background-image: url(/assets/nav/close.svg);
      }
    }
  }
`;

export const AccordianMenu = styled.div`
  > div {
    height: ${(props) => props.$visible ? 'auto' : '0' };
    overflow: hidden;
    margin-bottom: 10px;
  }

  ${(props) => props.$active && css`
    button {
      background-color: #EBF8F2;
      color: ${(props) => props.theme.colors.primary.main };
      font-weight: 500;
    }
  `}

  a.active {
    color: ${(props) => props.theme.colors.primary.main };

    &::before {
      background-color: ${(props) => props.theme.colors.primary.main };
    }
  }
`;