// styles
import { MainVisual, MainContent } from '../styles';

// ----------------------------------------------------------------------

export default function MainPage() {
  return (
    <MainVisual>
      <MainContent> 
        <p>
          다가오는 시대,<br />
          마주할 경험 <span>AIOT</span>
        </p>
        <div>
          <img src='/assets/main/visual-image.png' alt='main' />
        </div>
      </MainContent>
    </MainVisual>
  );
}