import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateMembers = async (memberId, data) => {
  return await axios.put(`members/${memberId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateMembers;