import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const createPotentialGroups = async (data) => {
  return await axios.post('potential-groups ', data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createPotentialGroups;