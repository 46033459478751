import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getUnregisteredSoils = async (setData, companyId, name, page) => {
  return await axios.get(`company-soils/companies/${companyId}/unregistered/search-page?name=${encodeURIComponent(name)}&page=${page}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getUnregisteredSoils;