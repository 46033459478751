import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const searchCompanies = async (setData, name, page) => {
  return await axios.get(`members/companies/search-page?name=${encodeURIComponent(name)}&page=${page}`)
    .then((response) => {
      setData(response.data.data);
      return response.data.data.content;
    })
    .catch(() => {});
};

export default searchCompanies;