import { css } from 'styled-components';

// ----------------------------------------------------------------------

const colors= {
  primary: {
    main: '#00A451',
    dark: '#009C4E'
  },
  error: '#FF5630',
  info: '#00B8D9',
  border: '#DCE0E4',
  gray: '#F2F3F5'
};

// ----------------------------------------------------------------------

export const breakpoints = {
  xs: 450,
  ssm: 630,
  sm: 768,
  md: 1024,
  mmd: 1220,
  lg: 1590,
  xl: 1900
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

// ----------------------------------------------------------------------

const theme = { colors, media };

export default theme;