import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialGroups = async (setData, name, page, size) => {
  return await axios.get(`potential-groups/search-page?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPotentialGroups;