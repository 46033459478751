import axios from '../../_utils/axios';
import message from '../../../utils/message';

// ----------------------------------------------------------------------

const checkCompanyName = async (name) => {
  return await axios.get(`companies/name/exists?name=${encodeURIComponent(name)}`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch(() => {
      return Promise.reject(
        new Error(
          message('COMMON', 'default')
        )
      );
    });
};

export default checkCompanyName;