import axios from '../_utils/axios';
import message from '../../utils/message';

// ----------------------------------------------------------------------

const changeExpiration = async () => {
  return await axios.put('accounts/password/next-time')
    .catch(() => {
      return Promise.reject(
        new Error(
          message('COMMON', 'default')
        )
      );
    });
};

export default changeExpiration;