import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateCompanies = async (companyId, name) => {
  return await axios.put(`companies/${companyId}`, {name})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateCompanies;