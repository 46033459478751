import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const refreshSoilDashboards = async (groupId) => {
  return await axios.get(`soil-dashboards/groups/${groupId}/refresh`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default refreshSoilDashboards;