import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getPassword = async (memberId) => {
  return await axios.post('members/issuance/temp-password', {memberId})
    .then((response) => {
      return response.data.data.tempPassword;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getPassword;