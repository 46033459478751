import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, dialog, setDialog }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  
  return (
    <>
      <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.id}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >

        <button
          onClick={() => {
            setDialog({ ...dialog,
              form: {
                visible: true,
                title: '수정',
                type: 'update',
                state: {
                  id: row.id,
                  name: row.name
                }
              }
            });
            setVisible(false);
          }}
        >
          수정
        </button>

        <button
          onClick={() => {
            setDialog({ ...dialog,
              delete: {
                visible: true,
                id: row.id
              }
            });
            setVisible(false);
          }}
        >
          삭제
        </button>

        <button
          onClick={() => {
            setDialog({ ...dialog,
              pic: {
                visible: true,
                id: row.id,
                name: row.picName ? row.picName : null
              }
            });
            setVisible(false);
          }}
        >
          담당자 설정
        </button>

        <button
          onClick={() => navigate('soil', {state: row})}
        >
          토양 그룹 설정
        </button>

        <button
          onClick={() => navigate('potential', {state: row})}
        >
          부식 전위 그룹 설정
        </button>

      </Popover>
    </>
  );
}