// store
import { useSelector } from '../../store';
// components
import Breadcrumbs from '../../components/breadcrumbs';
import { Table } from '../../components/table';
import ComingSoonIllustration from '../../components/illustration';
// hooks
import useResponsive from '../../hooks/useResponsive';
// styles
import { Content, Box, ScrollBar } from '../../styles';

// ----------------------------------------------------------------------

export default function SoilInfomationPage() {
  const isDesktop = useResponsive('up', 'sm');

  const group = useSelector(state => state.group?.soil);

  return (
    <Content>
      <Breadcrumbs
        heading='현장 설치 정보'
        links={[
          { name: '토양' },
          { name: '현장 설치 정보' }
        ]}
      />
      <Box>
        <Infomation group={group?.id} isDesktop={isDesktop} />
      </Box>
    </Content>
  );
}

function Infomation({ group, isDesktop }) {

  if ( group && group === 1 ) return (
    <>
      <p style={{fontSize: '20px', fontWeight: '600', padding: '20px 0 15px 25px'}}>센서 정보</p>
      <ScrollBar>
        <Table className='soil'>
          <thead>
            <tr>
              <th>센서 번호</th>
              <th colSpan={2}>설치 내역</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>1</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2878-7</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>G4IIRRMSVJ</td>
            </tr>
            <tr>
              <td rowSpan={3}>2</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2878-8</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>KN9IINS9XV</td>
            </tr>
            <tr>
              <td rowSpan={3}>3</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 3083</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>KRP3ZIVKCD</td>
            </tr>
            <tr>
              <td rowSpan={3}>4</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 3083</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>X1BLUW7EKC</td>
            </tr>
            <tr>
              <td rowSpan={3}>5</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2878-1</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>UVO6ASV6O3</td>
            </tr>
            <tr>
              <td rowSpan={3}>6</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2813-1</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>T2BR7B5BNK</td>
            </tr>
            <tr>
              <td rowSpan={3}>7</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2851-4</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>DWP97235UY</td>
            </tr>
            <tr>
              <td rowSpan={3}>8</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2851-4</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>GP9E3MYGT1</td>
            </tr>
            <tr>
              <td rowSpan={3}>9</td>
              <td>설치 일자</td>
              <td>2023.11.23</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>경상남도 양산시 원동면 화제리 2851-1</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>YX6J60T8CI</td>
            </tr>
          </tbody>
        </Table>
      </ScrollBar>

      <p style={{fontSize: '20px', fontWeight: '600', padding: '55px 0 10px 25px'}}>현장 설치 사진</p>
      
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 1' src='/assets/soil1/MPS-004.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 1]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 2' src='/assets/soil1/MPS-005.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 2]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 3' src='/assets/soil1/MPS-006.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 3]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 4' src='/assets/soil1/MPS-007.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 4]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 5' src='/assets/soil1/MPS-008.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 5]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 6' src='/assets/soil1/MPS-009.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 6]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 7' src='/assets/soil1/MPS-010.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 7]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 8' src='/assets/soil1/MPS-013.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 8]</p>
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 9' src='/assets/soil1/MPS-014.jpg' style={{width: '100%', borderRadius: '20px'}} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>[센서 9]</p>
        </div>
      </div>
    </>
  );
  if ( group && group === 2 ) return (
    <>
      <p style={{fontSize: '20px', fontWeight: '600', padding: '20px 0 15px 25px'}}>센서 정보</p>
      <ScrollBar>
        <Table className='soil'>
          <thead>
            <tr>
              <th>센서 번호</th>
              <th colSpan={2}>설치 내역</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>1</td>
              <td>설치 일자</td>
              <td>2023.08.03</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>전라남도 영광군 백수읍 하사리 1072-65</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>B9BTU42OVP</td>
            </tr>
            <tr>
              <td rowSpan={3}>2</td>
              <td>설치 일자</td>
              <td>2023.08.03</td>
            </tr>
            <tr>
              <td>설치 위치</td>
              <td>전라남도 영광군 백수읍 하사리 1072-65</td>
            </tr>
            <tr>
              <td>일련 번호</td>
              <td>YD1Y82WXGY</td>
            </tr>
          </tbody>
        </Table>

        <p style={{fontSize: '20px', fontWeight: '600', padding: '55px 0 10px 25px'}}>현장 설치 사진</p>
      
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
          <div style={{width: '100%', padding: '20px'}}>
            <img alt='현장 설치 9' src='/assets/soil2/image1.jpg' style={{width: '100%', borderRadius: '20px'}} />
          </div>
        </div>
      </ScrollBar>
    </>
  );

  return (
    <>
      <p style={{fontSize: '20px', fontWeight: '600', padding: '55px 0', textAlign: 'center'}}>센서 설치 완료 후 업데이트 예정입니다.</p>
      <ComingSoonIllustration />
    </>
  );
}