import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  width: 100%;
  padding: 35px 20px 0;
  display: flex;
  justify-content: center;
  position: relative;

  .pagination {
    display: flex;

    li {
      margin-left: 3px;
      &:first-child {
        margin-left: 0;
      }
    }

    a {
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 4px;
      border: 1px solid #E7E7E7;
      text-align: center;
      line-height: 32px;
      font-weight: 500;
    }

    .active a {
      color: #00A451;
      background-color: #D6F5E2;
      border: 1px solid #D6F5E2;
    }

    .arrow a {
      background-image: url(/assets/pagination/arrow.svg);
      background-size: 6px 10px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .double a {
      background-image: url(/assets/pagination/double_arrow.svg);
      background-size: 12px 10px;
    }

    .rotate a {
      transform: rotate(180deg);
    }
  }

  select {
    color: #919EAB;
    position: absolute;
    right: ${(props) => props.$dialog ? '0' : '20px' };
  }
`;