import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Inner = styled.div`
  width: 1590px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({theme}) => theme.media.lg`
    padding: 0 10px;
  `};
`;

export const Header = styled.header`
  width: 100%;
  height: 80px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;

  button {
    width: 120px;
    height: 35px;
    font-size: 15px;
    letter-spacing: .5px;
  }
`;

export const Logo = styled.div`
  width: 140px;
  height: 51px;
  background-image: url(/assets/logo/original.svg);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const Main = styled.main`
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Inner} {
    flex-direction: column;
    align-items: flex-start;
    background-image: url(/assets/logo/footer.svg);
    background-size: 129px 40px;
    background-position: right center;
    background-repeat: no-repeat;
  }
  
  p {
    color: #797979;
    font-size: 13px;
    
    span {
      color: #444444;
    }
  }
  
  p:first-child {
    line-height: 190%;
    letter-spacing: .5px;
  }
  
  p:last-child {
    font-size: 12px;
    margin-top: 15px;
    font-weight: 350;
  }
`;