import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #F2F3F5;
  border-radius: 5px;
  margin-bottom: 35px;
`;

export const Button = styled.button`
  width: 50%;
  color: #919EAB;
  font-size: 15px;
  position: relative;

  ${(props) => props.$active && css`
    color: #637381;

    &:before {
      content: '';
      display: block;
      width: 95%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: ${(props) => props.theme.colors.primary.main };
    }
  `}
`;