import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getSpecimens = async (setData, testCaseId, material, page, size) => {
  return await axios.get(`specimens/test-cases/${testCaseId}/search-page?material=${encodeURIComponent(material)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getSpecimens;