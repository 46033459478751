import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// apis
import { updatePotentialSerialNumber } from '../../../apis';

// ----------------------------------------------------------------------

  const schema = yup.object().shape({
    serialNumber: yup.string()
      .required()
      .matches(/^[A-Z][0-9A-Z]*$/, '영대문자 시작 / 영대문자, 숫자를 사용해 주세요.')
      .length(10, '10자로 입력해주세요.')
  });

// ----------------------------------------------------------------------

export default function SerialNumberForm({ dialog, setDialog, handleReset }) {

  const state = dialog.form;

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      serialNumber: state.serialNumber || ''
    }
  });

  const [ message, setMessage ] = useState(null);

  const onSubmit = async (data) => {
    await updatePotentialSerialNumber(state.id, data.serialNumber)
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          form: { visible: false }
        });
      })
      .catch(() => {
        setMessage('일련 번호를 수정할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  return (
    <Dialog
      title='일련 번호 수정'
      message={message}
      actions={
        <div>
          <button onClick={() => handleSubmit((data) => onSubmit(data))()} disabled={Object.keys(errors).length > 0 ? true : false}>
            수정
          </button>
          <button onClick={() => {
            setDialog({ ...dialog,
              form: { visible: false }
            });
          }}>
            취소
          </button>
        </div>
      }
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <TextField
          type='text'
          name='serialNumber'
          control={control}
        />
      </form>
    </Dialog>
  );
}