import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const settingPic = async (companyId, memberId) => {
  return await axios.put(`companies/${companyId}/pic`, {memberId})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default settingPic;