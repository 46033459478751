import axios from '../_utils/axios';
import message from '../../utils/message';
import store, { persistor, DELETE_TOKEN, DELETE_ACCOUNT, DELETE_GROUP } from '../../store';

// ----------------------------------------------------------------------

const logout = async ( request ) => {
  return await axios.post('auth/logout')
    .then(() => {
      if ( !request ) window.location.href = '/';
      store.dispatch( DELETE_TOKEN() );
      store.dispatch( DELETE_ACCOUNT() );
      store.dispatch( DELETE_GROUP() );
      persistor.purge();
    })
    .catch(() => {
      return Promise.reject(
        new Error(
          message('COMMON', 'default')
        )
      );
    });
};

export default logout;