import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// sections
import TableRow from './TableRow';
// import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, AffiliatedTitle, SearchWrapper, AddButton } from '../../../styles';
// apis
import { getSpecimens, deleteSpecimens } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

// ----------------------------------------------------------------------

export default function SpecimenPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const group = state?.group;
  const testCase = state?.testCase;

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);
  
  const [ dialog, setDialog ] = useState({ visible: false });
  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getSpecimens(setData, testCase.id, keyword, page, size)
    )();
    // eslint-disable-next-line
  }, [state]);

  const handlePage = (value) => {
    setPage(value - 1);
    getSpecimens(setData, testCase.id, keyword, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getSpecimens(setData, testCase.id, keyword, 0, Number(event.target.value));
  };

  const handleSearch = () => {
    setPage(0);
    getSpecimens(setData, testCase.id, keyword, 0, size);
  };

  const handleDelete = async () => {
    await deleteSpecimens(dialog.id)
      .then(() => {
        handleSearch();
        setDialog({ visible: false });
      })
      .catch(() => {
        setMessage('시편을 삭제할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  if ( !state ) return;

  return (
    <Content>
      <Breadcrumbs
        heading='시편 관리'
        links={[
          { name: '시스템 관리' },
          { name: '부식 전위 그룹 관리', link: '/system/potential-group' },
          { name: '테스트 케이스 관리', link: '/system/potential-group/test-case', state: state },
          { name: '시편 관리' }
        ]}
      />

      <Box>
        <AffiliatedTitle>
          <p>{ testCase.name }</p>
        </AffiliatedTitle>

        <SearchWrapper>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            handleSearch={handleSearch}
            handleInitialize={handleSearch}
            placeholder='재질을 입력하세요.'
          />

          <AddButton onClick={() => navigate('create', {state: { group: group, testCase: testCase }})}>
            등록
          </AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          dataRowRender={({data}) => <TableRow row={data} setDialog={setDialog} group={group} testCase={testCase} />}
        >
          <Column caption='시편 번호' dataField='number' alignment='center' />
          <Column caption='재질' dataField='material' alignment='center' />
          <Column caption='측정 위치' dataField='point' alignment='center' />
          <Column caption='센서 번호' dataField='sensorNumber' alignment='center' />
          <Column caption='채널' dataField='channel' alignment='center' />
          <Column caption='채널 설명' dataField='description' alignment='center' />
          <Column />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

        { dialog.visible &&
          <Dialog
            title='시편 삭제'
            icon='delete'
            message={message}
            actions={
              <div>
                <button onClick={() => handleDelete()}>
                  삭제
                </button>
                <button onClick={() => {
                  setDialog({ visible: false });
                  setMessage(null);
                }}>
                  취소
                </button>
              </div>
            }
          >
            <p>선택하신 시편을 삭제하시겠습니까?</p>
          </Dialog>
        }
        
      </Box>
    </Content>
  );
}