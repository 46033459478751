import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import TextField from '../../../components/text-field';
import Button from '../../../components/button';
import { Dialog } from '../../../components/dialog';
import Pagination from '../../../components/pagination';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// utils
import { REGEXP_SPECIAL } from '../../../utils/regexp';
// styles
import { Content, FormContent, Box, AffiliatedTitle, Label, WithButton, FieldWrapper, Message, ButtonWrapper } from '../../../styles';
// apis
import { searchChannels, checkSpecimensNumber, createSpecimens, updateSpecimens } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

const schema = yup.object().shape({
  number: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(1, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.')
    .max(9223372036854775807, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.'),
  material: yup.string()
    .required()
    .min(2, '2 - 30자 이내로 입력해주세요.')
    .max(30, '2 - 30자 이내로 입력해주세요.')
    .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.'),
  point: yup.string()
    .required()
    .min(2, '2 - 30자 이내로 입력해주세요.')
    .max(30, '2 - 30자 이내로 입력해주세요.')
    .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
});

// ----------------------------------------------------------------------

export default function SpecimenFormPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const group = state?.group;
  const testCase = state?.testCase;
  const specimen = state?.specimen;

  const pathname = useLocation().pathname.split('/')[5];
  const create = pathname === 'create' ? true : false;
  const title = create ? '등록' : '수정';

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: specimen?.number || 1,
      material: specimen?.material || '',
      point: specimen?.point || ''
    }
  });
  
  const [ visible, setVisible ] = useState(false);

  const [ channels, setChannels ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = channels;
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  const [ select, setSelect ] = useState({ id: null, name: null });
  const [ channel, setChannel ] = useState( specimen ? { id: specimen.channelId, name: `센서 ${specimen.sensorNumber} - 채널 ${specimen.channel}`} : { id: null, name: '' } );
  const [ required, setRequired ] = useState(false);

  const [ auto, setAuto ] = useState(create);
  const [ checked, setChecked ] = useState({ result: null, number: null });
  const [ message, setMessage ] = useState({ channels: null, checked: null, error: null });

  useEffect( () => {
    if ( !state  ) return navigate(-1);
    ( async () =>
      await searchChannels(setChannels, group.id, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value - 1);
    searchChannels(setChannels, group.id, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    searchChannels(setChannels, group.id, 0, Number(event.target.value));
  };

  const handleChannels = () => {
    if ( !select.id ) return setMessage({ ...message, channels: '채널을 선택해주세요.' });
    setChannel(select);
    setVisible(false);
    setRequired(false);
    setMessage({ ...message, channels: null });
  };

  const handleCancel = () => {
    setVisible(false);
    if ( !channel.id ) return setSelect({ id: null, name: null });
    if ( channel.id !== select.id ) return setSelect(channel);
  };

  const handleChecked = async () => {
    const value = getValues('number');

    await checkSpecimensNumber(testCase.id, value)
      .then((response) => {
        setChecked({ result: !response, number: value });
        setMessage({ ...message, checked: !response ? '사용하실 수 있는 시편 번호입니다.' : '이미 등록된 시편 번호입니다.' });
      })
      .catch((error) => {
        setMessage({ ...message, checked: error.message });
      });
  };

  const onError = () => {
    if ( !channel.id ) return setRequired(true);
  };

  const onSubmit = async (data) => {
    if ( !channel.id ) return setRequired(true);

    if ( !auto
      && ( checked.number !== getValues('number') || checked.result === null )
      && ( create || (!create && Number(getValues('number')) !== specimen.number) ) 
    ) {
      setChecked({ result: null, number: null });
      setMessage({ ...message, checked: '중복 체크 후 진행하실 수 있습니다.' });
    } else if ( checked.result || (!create && Number(getValues('number')) === specimen.number) || ( create && auto ) ) {
      let result = { number: getValues('number'), material: data.material, point: data.point };
      if ( create ) {
        result = { ...result, channelId: channel.id, testCaseId: testCase.id, autoNumber: auto };
        await createSpecimens(result)
          .then(() => {
            navigate('/system/potential-group/test-case/specimen', {state: { group: group, testCase: testCase, submit: true }});
          })
          .catch(() => {
            setMessage({ ...message, error: '시편을 등록할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      } else {
        await updateSpecimens(specimen.channelId, result)
          .then(() => {
            navigate('/system/potential-group/test-case/specimen', {state: { group: group, testCase: testCase, submit: true }});
          })
          .catch(() => {
            setMessage({ ...message, error: '시편을 수정할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      }
    }
  };

  return (
    <Content>
      <Breadcrumbs
        heading={`시편 ${title}`}
        links={[
          { name: '시스템 관리' },
          { name: '부식 전위 그룹 관리', link: '/system/potential-group' },
          { name: '테스트 케이스 관리', link: '/system/potential-group/test-case', state: state },
          { name: '시편 관리', link: '/system/potential-group/test-case/specimen', state: state },
          { name: `시편 ${title}` }
        ]}
      />

      <FormContent>
        <Box>
          <AffiliatedTitle $form>
            <p>{ testCase?.name }</p>
          </AffiliatedTitle>

          <form onSubmit={ handleSubmit(onSubmit, onError) }>

            <WithButton $error={required}>
              <Label>채널</Label>
              <div>
                <input type='text' value={channel.name} disabled />
                <button type='button' onClick={() => setVisible(true)} disabled={!create}>검색</button>
              </div>
            </WithButton>

            <WithButton>
              <Label>시편 번호</Label>
              <div>
                <TextField
                  type='number'
                  name='number'
                  control={control}
                  disabled={auto}
                />
                <button type='button' onClick={() => handleChecked()} disabled={auto}>중복 확인</button>
              </div>
            </WithButton>

            <FieldWrapper $flex>
              <Message $success={ checked.result }>{ message.checked }</Message>

              { create &&
                <div>
                  <input type='checkbox' id='checkbox' checked={auto} onChange={(event) => setAuto(event.target.checked)} />
                  <label htmlFor='checkbox'>
                    <span>AUTO</span>
                  </label>
                </div>
              }
            </FieldWrapper>

            <TextField
              type='text'
              label='재질'
              name='material'
              control={control}
            />

            <TextField
              type='text'
              label='측정 위치'
              name='point'
              control={control}
            />

            { message.error &&
              <Message>{ message.error }</Message>
            }

            <ButtonWrapper>
              <Button label={title} disabled={Object.keys(errors).length > 0 ? true : false} />
              <button type='button' onClick={() => navigate(-1)}>취소</button>
            </ButtonWrapper>
          </form>
        </Box>
      </FormContent>

      { visible &&
        <Dialog
          message={message.channels}
          actions={
            <div>
              <button onClick={() => handleChannels()} disabled={ !select.id ? true : false}>
                적용
              </button>
              <button onClick={() => handleCancel()}>
                취소
              </button>
            </div>
          }
          type={'none'}
        >
          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            dataRowRender={({data}) => <TableRow row={data} select={select} setSelect={setSelect} />}
          >
            <Column />
            <Column caption='센서 번호' dataField='sensorNumber' alignment='center' />
            <Column caption='채널' dataField='channel' alignment='center' />
            <Column caption='채널 설명' dataField='description' alignment='center' />
          </DataGrid>

          <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} type={'dialog'} />

        </Dialog>
      }
    </Content>
  );
}

function TableRow({ row, select, setSelect }) {
  return (
    <tr onClick={() => setSelect({ id: row.channelId, name: `센서 ${row.sensorNumber} - 채널 ${row.channel}` })}>
      <td>
        <input type='radio' name='channel' checked={ select.id && select.id === row.channelId ? true : false } readOnly />
      </td>
      <td>{ row.sensorNumber }</td>
      <td>{ row.channel }</td>
      <td>{ row.description }</td>
    </tr>
  );
}