// store
import { useSelector } from '../../store';
// components
import Breadcrumbs from '../../components/breadcrumbs';
import { Table } from '../../components/table';
import ComingSoonIllustration from '../../components/illustration';
// hooks
import useResponsive from '../../hooks/useResponsive';
// styles
import { Content, Box, ScrollBar } from '../../styles';

// ----------------------------------------------------------------------

export default function PotentialInfomationPage() {
  const isDesktop = useResponsive('up', 'sm');

  const group = useSelector(state => state.group?.potential);

  return (
    <Content>
      <Breadcrumbs
        heading='현장 설치 정보'
        links={[
          { name: '부식 전위' },
          { name: '현장 설치 정보' }
        ]}
      />
      <Box>
        { group.id === 1 ? (
          <Infomation isDesktop={isDesktop} />
        ) : (
          <>
            <p style={{fontSize: '20px', fontWeight: '600', padding: '55px 0', textAlign: 'center'}}>센서 설치 완료 후 업데이트 예정입니다.</p>
            <ComingSoonIllustration />
          </>
        )}
      </Box>
    </Content>
  );
}

function Infomation({isDesktop}) {
  return (
    <>
      <p style={{fontSize: '20px', fontWeight: '600', padding: '20px 0 15px 25px'}}>센서 채널 정보</p>
    
      <ScrollBar>
        <Table className='potential'>
          <thead>
            <tr>
              <th>Case</th>
              <th>장비 번호</th>
              <th>채널</th>
              <th>채널 이름</th>
              <th>Material</th>
              <th>Measurement Point</th>
              <th>Coating</th>
              <th>Mortar</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={4}>1</td>
              <td rowSpan={4}>No.1</td>
              <td>1</td>
              <td>C1-NON-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={4}>X</td>
              <td rowSpan={4}>X</td>
              <td rowSpan={4}>
                <img alt='case-1' src='/assets/dashboard/case1.png' style={{width: '110px'}} />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>C1-NON-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C1-NON-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C1-NON-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={24}>2</td>
              <td rowSpan={16}>No.2</td>
              <td>1</td>
              <td>C2-PET-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={8}>Petrolatum Taping</td>
              <td rowSpan={8}>X</td>
              <td rowSpan={24}>
                <img alt='case-2' src='/assets/dashboard/case2.png' style={{width: '110px'}} />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>C2-PET-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C2-PET-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C2-PET-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>5</td>
              <td>C2-PET-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>6</td>
              <td>C2-PET-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C2-PET-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C2-PET-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>9</td>
              <td>C2-URE-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={8}>Urethane Painting</td>
              <td rowSpan={8}>X</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C2-URE-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>11</td>
              <td>C2-URE-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>12</td>
              <td>C2-URE-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>13</td>
              <td>C2-URE-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>14</td>
              <td>C2-URE-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>15</td>
              <td>C2-URE-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>16</td>
              <td>C2-URE-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={8}>No.3</td>
              <td>1</td>
              <td>C2-EPO-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={8}>Epoxy Painting</td>
              <td rowSpan={8}>X</td>
            </tr>
            <tr>
              <td>2</td>
              <td>C2-EPO-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C2-EPO-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C2-EPO-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>5</td>
              <td>C2-EPO-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>6</td>
              <td>C2-EPO-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C2-EPO-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C2-EPO-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={8}>3</td>
              <td rowSpan={8}>No.1</td>
              <td>5</td>
              <td>C3-NON-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={8}>X</td>
              <td rowSpan={8}>O</td>
              <td rowSpan={8}>
                <img alt='case-3' src='/assets/dashboard/case3.png' style={{width: '110px'}} />
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>C3-NON-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C3-NON-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C3-NON-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>9</td>
              <td>C3-NON-MORT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Mortar</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C3-NON-MORT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>11</td>
              <td>C3-NON-MORT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>12</td>
              <td>C3-NON-MORT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={36}>4</td>
              <td rowSpan={12}>No.4</td>
              <td>1</td>
              <td>C4-PET-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={12}>Petrolatum Taping</td>
              <td rowSpan={12}>O</td>
              <td rowSpan={36}>
                <img alt='case-4' src='/assets/dashboard/case4.png' style={{width: '110px'}} />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>C4-PET-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C4-PET-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C4-PET-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>5</td>
              <td>C4-PET-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>6</td>
              <td>C4-PET-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C4-PET-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C4-PET-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>9</td>
              <td>C4-PET-MORT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Mortar</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C4-PET-MORT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>11</td>
              <td>C4-PET-MORT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>12</td>
              <td>C4-PET-MORT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={12}>No.5</td>
              <td>1</td>
              <td>C4-URE-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={12}>Urethane Painting</td>
              <td rowSpan={12}>O</td>
            </tr>
            <tr>
              <td>2</td>
              <td>C4-URE-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C4-URE-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C4-URE-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>5</td>
              <td>C4-URE-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>6</td>
              <td>C4-URE-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C4-URE-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C4-URE-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>9</td>
              <td>C4-URE-MORT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Mortar</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C4-URE-MORT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>11</td>
              <td>C4-URE-MORT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>12</td>
              <td>C4-URE-MORT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td rowSpan={12}>No.6</td>
              <td>1</td>
              <td>C4-EPO-SOIL-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Soil</td>
              <td rowSpan={12}>Epoxy Painting</td>
              <td rowSpan={12}>O</td>
            </tr>
            <tr>
              <td>2</td>
              <td>C4-EPO-SOIL-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>3</td>
              <td>C4-EPO-SOIL-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>C4-EPO-SOIL-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>5</td>
              <td>C4-EPO-COAT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Coating</td>
            </tr>
            <tr>
              <td>6</td>
              <td>C4-EPO-COAT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>7</td>
              <td>C4-EPO-COAT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>C4-EPO-COAT-PS</td>
              <td>PosMAC Super</td>
            </tr>
            <tr>
              <td>9</td>
              <td>C4-EPO-MORT-SS</td>
              <td>SS400</td>
              <td rowSpan={4}>Mortar</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C4-EPO-MORT-HD</td>
              <td>HDZ55</td>
            </tr>
            <tr>
              <td>11</td>
              <td>C4-EPO-MORT-PM</td>
              <td>PosMAC 3.0</td>
            </tr>
            <tr>
              <td>12</td>
              <td>C4-EPO-MORT-PS</td>
              <td>PosMAC Super</td>
            </tr>
          </tbody>
        </Table>
      </ScrollBar>

      <p style={{fontSize: '20px', fontWeight: '600', padding: '55px 0 10px 25px'}}>현장 설치 사진</p>
      
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 1' src='/assets/dashboard/onsite1.png' style={{width: '100%', borderRadius: '20px'}} />
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 2' src='/assets/dashboard/onsite2.png' style={{width: '100%', borderRadius: '20px'}} />
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 3' src='/assets/dashboard/onsite3.png' style={{width: '100%', borderRadius: '20px'}} />
        </div>
        <div style={{width: isDesktop ? '650px' : '100%', padding: '20px'}}>
          <img alt='현장 설치 4' src='/assets/dashboard/onsite4.jpg' style={{width: '100%', borderRadius: '20px'}} />
        </div>
      </div>
    </>
  );
}