import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const unblockMembers = async (memberId) => {
  return await axios.delete(`members/${memberId}/block`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default unblockMembers;