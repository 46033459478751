import styled, { css } from 'styled-components';

// ---------------------------------------------------------------------------

export const ErrorPages = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-top: 50px;
  }
  > *:first-child {
    margin-top: 0;
  }
`;

export const FormContent = styled.div`
  width: 450px;

  ${({theme}) => theme.media.xs`
    padding: 0 10px;
  `};
`;

export const Box = styled.div`
  width: 100%;
  min-height: calc(100vh - 250px);
  padding-bottom: 30px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
`;

export const Content = styled.div`
  width: 1590px;
  min-height: calc(100vh - 70px);
  padding-bottom: 50px;

  ${FormContent} {
    width: 550px;
    margin: 0 auto;

    ${Box} {
      padding: 50px;
      height: auto;
    }
  }

  ${({theme}) => theme.media.xl`
    width: 100%;
    padding: 0 10px;
  `};
`;

export const Message = styled.p`
  font-size: 13px;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${(props) => props.$success ? props.theme.colors.info : props.theme.colors.error };

  ${(props) => props.$info && css`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.info };

    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      background-image: url(/assets/message_info.svg);
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  `}
`;

export const Tabs = styled.div`
  height: 38px;
  border-radius: 10px;
  border: 1px solid #DCE0E4;
  overflow: hidden;
  display: flex;

  button:first-child {
    border-right: 1px solid #DCE0E4;
  }
`;

export const Tab = styled.button`
  width: 58px;
  height: 100%;
  color: #919EAB;

  ${(props) => props.$active && css`
    color: #444444;
    background-color: #F2F3F5;
  `}
`;

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 25px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: flex-end;
  }

  select {
    height: 38px;
    margin-right: 10px;
    min-width: 90px;
    text-align: center;
  }
  
  > div > p {
    padding-left: 10px;
  }

  ${Tabs} {
    margin-right: 10px;
  }

  ${({theme}) => theme.media.ssm`
    flex-direction: column;

    > div {
      flex-wrap: wrap;
      justify-content: space-between;

      select, ${Tabs} {
        width: 49%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .dx-texteditor.dx-editor-filled.small, > button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
      
      > button {
        background-image: none;
        padding: 0;
      }

      ${Tabs} > button {
        width: 50%;
      }
    }
      
    > button {
      background-image: none;
      padding: 0;
    }
  `};
`;

export const SearchButton = styled.button`
  height: 38px;
  padding: 0 12px 0 35px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.primary.main };
  background-image: url(/assets/inputs/search.svg);
  background-size: 20px 20px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  color: #FFFFFF;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark };
  }
`;

export const DownloadButton = styled.button`
  height: 38px;
  padding: 0 12px 0 39px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.border };
  background-image: url(/assets/inputs/download.svg);
  background-size: 24px 24px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  color: #637381;
  font-weight: 500;

  &:hover {
    background-color: #D5D8DC;
  }
`;

export const AffiliatedTitle = styled.div`
  width: 100%;
  padding: 25px 20px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px dashed ${(props) => props.theme.colors.border };

  p {
    font-size: 18px;
    font-weight: 500;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #637381;
    padding-left: 22px;
  }

  ${(props) => props.$form && css`
    padding: 0 0 20px;
    border-bottom: none;
  `}
`;

export const AddButton = styled.button` 
  height: 38px;
  border-radius: 10px;
  background-color: #36B37D;
  padding: 8px 20px 8px 45px;
  background-image: url(/assets/inputs/add.png);
  background-size: 20px 20px;
  background-position: left 20px center;
  background-repeat: no-repeat;
  color: #FFFFFF;

  &:hover {
    background-color: #34AE79;
  }
`;

export const TableMoreButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(/assets/inputs/table-more.png);
  background-size: 4px 20px;
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray };
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    width: 49%;
    letter-spacing: 1px;
  }

  button:last-child {
    height: 45px;
    background-color: #FFFFFF;
    color: #637381;
    border: 1px solid #DCE0E4;
    font-size: 16px;
    border-radius: 10px;
  }
`;

// DASHBOARD ----------------------------------------------------------

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;

  ${(props) => props.$active && css`
    ${Panels} {
      left: -350px;
    }

    ${MapSearchWrapper} {
      left: 20px;
    }

    ${DataPopup} {
      left: 30px;
    }

    ${SliderButton} {
      > div {
        transform: rotate(-180deg);
      }
    }
  `}

  ${(props) => props.$active && props.theme.media.sm`
    ${Panels} {
      left: calc(-100% + 25px);
    }

    ${DataPopup} {
      left: 0;
      transform: translateY(0);
    }
  `};

  ${(props) => props.$active && props.theme.media.mmd`
    ${MapSearchWrapper} {
      left: 50%;
    }
  `};
`;

export const Panels = styled.div`
  width: 350px;
  height: 100%;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.5),0px 12px 20px -4px rgba(145, 158, 171, 0.5);
  transition: all .2s ease-in-out;

  > div {
    > div {
      width: 100%;
      height: 20%;
      min-height: 155px;
      padding: 30px;
      border-bottom: 1px dashed ${(props) => props.theme.colors.border };
    }
  
    > div:nth-child(2) {
      height: 16%;
      min-height: 190px;

      > div {
        display: flex;
        justify-content: space-between;
        height: calc(100% - 34px);

        > div {
          width: 32%;
          text-align: center;
          border: 1px solid #EEEEEE;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          > span {
            font-size: 28px;
            font-weight: bold;
            padding-top: 8px;
          }

          &:hover {
            > span {
              text-decoration: underline;
            }
          }

          &:nth-child(2) span {
            color: #FF5630;
          }

          &:last-child span {
            color: #FFAB00;
          }
        }
      }
    }
  
    > div:nth-child(3) {
      height: 64%;
      border-bottom: none;
    }

    > div:not(:first-child) {
      > p {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 10px;
        display: flex;
        align-items: flex-end;

        i {
          display: block;
          width: 14px;
          height: 14px;
          background-image: url(/assets/message_info.svg);
          background-size: 100% auto;
          background-position: center center;
          background-repeat: no-repeat;
          margin: 0 0 2px 5px;
        }
      }
    }
  }

  ${({theme}) => theme.media.sm`
    width: calc(100% - 25px);

    > div {
      > div {
        height: 145px;
        padding: 20px;
      }
    
      > div:last-child {
        height: auto;
      }
  
      > div:nth-child(2) {
        > div {
          > div {
            > span {
              padding-top: 0;
            }
          }
        }
      }
  
      > div:nth-child(3) {
        padding-bottom: 50px;
      }
    }
  `};
`;

export const ScrollBar = styled.div`
  width: 100%;
  height: 100%;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 115, 129, 0.3);
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  },
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const Weathers = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  > div {
    width: 100%;

    > div {
      display: flex;
    }

    > div:last-child {
      align-items: flex-end;
      
      span {
        font-size: 15px;
        line-height: 17px;
        color: #999999;
        font-weight: 500;
        margin-right: 8px;
      }

      p {
        font-size: 18px;
        line-height: 18px;
        margin-right: 2px;
      }

      i {
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: -.5px;
        margin-right: 12px;
      }
    }
  }
`;

export const WeatherIcon = styled.div`
  align-items: center;
  margin-bottom: 25px;

  > div:first-child {
    width: 70px;
    height: 70px;
    background-image: url(/assets/weathers/${(props) => props.$icon}.png);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 20px;
  }

  > div:last-child {
    display: flex;
    align-items: flex-end;

    > p:first-child {
      font-size: 44px;
      line-height: 44px;
    }

    > p:last-child {
      font-size: 16px;
      line-height: 21px;
      margin-left: 10px;
    }
  }

  ${({theme}) => theme.media.sm`
    margin-bottom: 15px;
  `};
`;

export const ChartWrapper = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16.5px;
      font-weight: 500;
    }

    button {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-image: url(/assets/dashboard/list.svg);
      background-size: 19px 13px;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      z-index: 2;
    
      &:hover {
        background-color: ${(props) => props.theme.colors.gray };
      }
    }
  }
`;

export const Chart = styled.div`
  width: 100%;
  height: calc(100% - 59px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Maps = styled.div`
  width: 100%;
  height: 100%;

  .marker {
    display: flex;
    align-items: center;

    > span {
      margin-left: 5px;
      padding: 2px 5px;
      border-radius: 5px;
      background-color: #FFFFFF;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }

    > div {
      width: 30px;
      height: 39px;
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
`;

export const DataPopup = styled.div`
  background-color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 380px;
  transform: translateY(-50%);
  padding: 20px 30px;
  border-radius: 10px;
  transition: all .2s ease-in-out;
  z-index: 1000;

  > button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/assets/nav/close.svg);
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      background-color: #F1F1F1;
    }
  }

  > p {
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 15px;
    text-align: center;
  }

  > table {
    border-spacing: 0px;
  }

  > table tr td:first-child div {
    font-weight: bold;
    padding-right: 15px;
  }

  > table tr:first-child td {
    border-top: 1px solid #EEEEEE;
  }

  > table tr td {
    padding: 10px 3px;
    border-bottom: 1px solid #EEEEEE;
  }

  ${({theme}) => theme.media.sm`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

export const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 13px;
  height: 34px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;

  div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #FF5630;
    margin: 0 5px 0 10px;
  }
  > div:nth-child(2) {
    background-color: #00B8D9;
    margin: 0 5px 0 15px;
  }
  > div:nth-last-child(2) {
    background-color: #FFAB00;
  }

  p {
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    
    &:first-child {
      font-weight: bold;
      color: #666666;
    }
  }

  ${({theme}) => theme.media.mmd`
    top: 64px;
    right: 50%;
    transform: translateX(50%);
  `};
`;

export const MapSearchWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 370px;
  z-index: 1;
  display: flex;
  transition: all .2s ease-in-out;

  button {
    margin-left: 5px;
    padding: 0 10px 0 36px;
    height: 34px;
    border-radius: 17px;
    background-color: #FFFFFF;
    background-image: url(/assets/dashboard/refresh.png);
    background-size: 24px 24px;
    background-position: 8px center;
    background-repeat: no-repeat;
    white-space: nowrap;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);

    &:first-child {
      margin-left: 0;
      background-image: url(/assets/dashboard/map.png);
    }
    
    &:hover {
      color: #00B8D9;
    }
    
    &:disabled {
      background-color: #F4F6F8;
      opacity: 0.8;
    }
  }

  ${({theme}) => theme.media.mmd`
    left: 50%;
    transform: translateX(-50%);
  `};
`;

export const SliderButton = styled.button`
  width: 18px;
  height: 32px;
  background-color: #FFFFFF;
  border-radius: 0 6px 6px 0;
  border: 1px solid #F3F5F6;
  border-left: none;
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 6px;
    height: 10px;
    background-image: url(/assets/dashboard/slider.svg);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  ${({theme}) => theme.media.md`
    width: 25px;
    height: 36px;
    right: -25px;
    background-size: 8px 12px;
  `};
`;

export const AnalysisChart = styled.div`
  width: 100%;
  padding: 0 20px;

  p {
    margin: 50px 0 15px;
    font-size: 17.5px;
    font-weight: 500;
  }

  p:first-child {
    margin: 20px 0 15px;
  }
`;

// INPUTS -------------------------------------------------------------

export const Select = styled.select`
  width: 100%;
  height: 45px;

  & + p {
    margin-top: 20px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #DCE0E4;
`;

export const WithButton = styled.div`
  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > input {
      width: calc(100% - 110px);
      height: 45px;
      border-radius: 10px;
      border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.border };
      padding: 0 10px;
    }

    > div {
      width: calc(100% - 110px);
    }
  }

  button {
    width: 100px;
    height: 45px;
    border-radius: 10px;
    background-color: #F2F3F5;
    color: #637381;
    font-weight: 500;
  }
  button:hover {
    background-color: #F0F1F3;
  }
  button:disabled {
    cursor: default;
    color: #bbc9d7;
    background-color: #F0F1F3;
  }
`;

export const Label = styled.p`
  font-size: 13px !important;
  font-weight: 500;
  padding-bottom: 3px;
  text-align: left !important;
`;

export const IssuancePassword = styled.div`
  text-align: center;

  p:first-child {
    font-size: 18px;
    color: #00AB55;
    font-weight: 500;
  }

  p:last-child {
    font-size: 14px;
    color: #637381;
    font-weight: 500;
  }

  div {
    wdith: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    background-color: #F6F7F8;
    font-size: 18px;
    font-weight: 500;
    margin: 30px 0;
  }
`;

export const FieldWrapper = styled.div`
  input {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 1px solid  ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.border };
    padding: 0 10px;
  }

  ${(props) => props.$flex && css`
    display: flex;
    justify-content: space-between;
    margin-top: 0;

    label {
      margin-top: 3px;
    }
  `}
`;

export const TagWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;

  button {
    display: flex;
    align-items: center;
    background-color: #D6F5E2;
    height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    margin: 5px;

    p {
      color: #00A451;
      font-size: 13px;
      font-weight: 500;
    }

    div {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary.main };
      background-image: url(/assets/inputs/delete.svg);
      background-size: 8px 8px;
      background-position: center center;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }
`;

// AUTH / ACCOUNT ------------------------------------------------------------

export const Logo = styled.div`
  width: 183px;
  height: 43px;
  background-image: url(/assets/logo/black.svg);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto 60px;
`;

export const AccountTitle = styled.div`
  text-align: center;
  margin-bottom: 45px;

  p {
    font-family: 'S-CoreDream';
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 8px;
  }

  span {
    font-size: 16px;
    color: #797979;
    line-height: 220%;
    margin-top: 25px;
  }
`;

export const NextButton = styled.button`
  width: 100%;
  height: 45px;
  color: ${(props) => props.theme.colors.primary.main };
  font-size: 15px;
  letter-spacing: .5px;
  border-radius: 10px;
  font-weight: 500;
  margin-top: 20px;

  &:hover {
    background-color: rgba(0, 171, 85, 0.06);
  }
`;

// MAIN ----------------------------------------------------------------------

export const MainVisual = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/assets/main/visual-bg.png);
  background-size: 1920px 1000px;
  background-position: center center;
  background-repeat: no-repeat;

  p {
    font-family: 'S-CoreDream';
    font-size: 80px;
  
    span {
      font-weight: 800;
      letter-spacing: 5px;
    }
  }

  > div > div {
    width: 833px;
    height: 820px;

    img {
      width: 100%;
      height: auto;
    }
  }

  ${({theme}) => theme.media.lg`
    p {
      font-size: 4.5em;
    }

    > div > div {
      width: 49%;
      height: auto;
    }
  `};

  ${({theme}) => theme.media.md`
    ${MainContent} {
      flex-direction: column;
      justify-content: center;
    }

    > div > div {
      width: 75%;
    }
  `};

  ${({theme}) => theme.media.sm`
    p {
      font-size: 3em;
    }
  `};
`;


export const MainContent = styled.div`
  width: 1590px;
  min-height: calc(100vh - 80px);
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({theme}) => theme.media.lg`
    width: 100%;
    padding: 0 10px;
  `};

  ${({theme}) => theme.media.md`
    flex-direction: column;
    justify-content: center;
  `};
`;