import { useRoutes, Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

import MainLayout from '../layouts/main/MainLayout';
import Layout from '../layouts/common/Layout';

// ----------------------------------------------------------------------

import MainPage from '../pages/MainPage';

import LoginPage from '../pages/auth-account/LoginPage';
import ChangePasswordPage from '../pages/auth-account/ChangePasswordPage';

import AccountPage from '../pages/auth-account/AccountPage';

//토양
import SoilDashboardPage from '../pages/soil/SoilDashboardPage';
import SoilAnalysisPage from '../pages/soil/SoilAnalysisPage';
import SoilInfomationPage from '../pages/soil/SoilInfomationPage';

// 부식 전위
import PotentialDashboardPage from '../pages/potential/PotentialDashboardPage';
import PotentialAnalysisPage from '../pages/potential/PotentialAnalysisPage';
import PotentialInfomationPage from '../pages/potential/PotentialInfomationPage';

import LoginLogPage from '../pages/admin/login-log/LoginLogPage';

import MemberPage from '../pages/admin/member/MemberPage';
import MemberFormPage from '../pages/admin/member/MemberFormPage';

import CompanyPage from '../pages/admin/company/CompanyPage';
import CompanySoilPage from '../pages/admin/company-soil/CompanySoilPage';
import CompanyPotentialPage from '../pages/admin/company-potential/CompanyPotentialPage';

import SoilGroupPage from '../pages/admin/soil-group/SoilGroupPage';
import SoilGroupFormPage from '../pages/admin/soil-group/SoilGroupFormPage';

import SoilSensorPage from '../pages/admin/soil-sensor/SoilSensorPage';
import SoilSensoFormPage from '../pages/admin/soil-sensor/SoilSensoFormPage';

import PotentialGroupPage from '../pages/admin/potential-group/PotentialGroupPage';
import PotentialGroupFormPage from '../pages/admin/potential-group/PotentialGroupFormPage';

import PotentialSensorPage from '../pages/admin/potential-sensor/PotentialSensorPage';
import PotentialSensorFormPage from '../pages/admin/potential-sensor/PotentialSensorFormPage';

import ChannelPage from '../pages/admin/channel/ChannelPage';

import TestCasePage from '../pages/admin/test-case/TestCasePage';

import SpecimenPage from '../pages/admin/specimen/SpecimenPage';
import SpecimenFormPage from '../pages/admin/specimen/SpecimenFormPage';

import Page404 from '../pages/error/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([

    { element: <MainLayout />,
      children: [
        { index: true, element: <MainPage /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'change-password', element: <ChangePasswordPage /> }
      ]
    },

    { element: <Layout />,
      children: [
        { path: 'account', element: <AccountPage /> },

        { path: 'soil',
          children: [
            { path: 'dashboard', element: <SoilDashboardPage /> },
            { path: 'analysis', element: <SoilAnalysisPage /> },
            { path: 'infomation', element: <SoilInfomationPage /> }
          ]
        },

        { path: 'potential',
          children: [
            { path: 'dashboard', element: <PotentialDashboardPage /> },
            { path: 'analysis', element: <PotentialAnalysisPage /> },
            { path: 'infomation', element: <PotentialInfomationPage /> }
          ]
        },

        { path: 'manage/login-log', element: <LoginLogPage /> },

        { path: 'system',
          children: [

            { path: 'member',
              children: [
                { index: true, element: <MemberPage /> },
                { path: 'create', element: <MemberFormPage /> },
                { path: 'update', element: <MemberFormPage /> }
              ]
            },

            { path: 'company',
              children: [
                { index: true, element: <CompanyPage /> },
                { path: 'soil', element: <CompanySoilPage /> },
                { path: 'potential', element: <CompanyPotentialPage /> }
              ]
            },

            { path: 'soil-group',
              children: [
                { index: true, element: <SoilGroupPage /> },
                { path: 'create', element: <SoilGroupFormPage /> },
                { path: 'update', element: <SoilGroupFormPage /> },

                { path: 'soil-sensor',
                  children: [
                    { index: true, element: <SoilSensorPage /> },
                    { path: 'create', element: <SoilSensoFormPage /> },
                    { path: 'update', element: <SoilSensoFormPage /> }
                  ]
                }
              ]
            },

            { path: 'potential-group',
              children: [
                { index: true, element: <PotentialGroupPage /> },
                { path: 'create', element: <PotentialGroupFormPage /> },
                { path: 'update', element: <PotentialGroupFormPage /> },

                { path: 'potential-sensor',
                  children: [
                    { index: true, element: <PotentialSensorPage /> },
                    { path: 'create', element: <PotentialSensorFormPage /> },
                    { path: 'update', element: <PotentialSensorFormPage /> },

                    { path: 'potential-channel', element: <ChannelPage /> }
                  ]
                },

                { path: 'test-case',
                  children: [
                    { index: true, element: <TestCasePage /> },

                    { path: 'specimen',
                      children: [
                        { index: true, element: <SpecimenPage /> },
                        { path: 'create', element: <SpecimenFormPage /> },
                        { path: 'update', element: <SpecimenFormPage /> }
                      ]
                    }
                  ]
                }
              ]
            }

          ]
        },
      ]
    },

    { path: '404', element: <Page404 /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);

  return routes;
}