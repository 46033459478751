// prop-types
import PropTypes from 'prop-types';
// styles
import { Overlay, Wrapper, Title, Content, Message, Actions, Icon } from './styles';

// ----------------------------------------------------------------------

Dialog.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.element
};

// ----------------------------------------------------------------------

export default function Dialog({ title, children, actions, icon, message, type }) {
  return (
    <Overlay>
      <Wrapper>
        { title &&
          <Title $margin={icon && true}>{ title }</Title>
        }

        <Content $type={type === 'none' && true}>
          { icon &&
            <Icon $name={icon} />
          }

          { children }
        </Content>

        <Message>{ message }</Message>

        { actions &&
          <Actions>
            { actions }
          </Actions>
        }

      </Wrapper>
    </Overlay>
  );
}