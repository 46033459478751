import { useNavigate } from 'react-router-dom';
// prop-types
import PropTypes from 'prop-types';
// styles
import { Wrapper } from './styles';

// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.array
};

// ----------------------------------------------------------------------

export default function Breadcrumbs({ heading, links }) {
  const navigate  = useNavigate();

  const breadcrumbs = links.map((item) => {
    if ( item.link ) {
      return <li key={item.name}><button onClick={() => navigate(item.link, {state: item.state ? item.state : undefined})}>{ item.name }</button></li>;
    } else {
      return <li key={item.name}><p>{ item.name }</p></li>;
    }
  })

  return (
    <Wrapper>
      <ul>
        { breadcrumbs }
      </ul>
      
      <p>
        { heading }
      </p>
    </Wrapper>
  );
}