import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import store, { SET_SOIL_GROUP, SET_POTENTIAL_GROUP, useSelector } from '../../store';
// components
import SearchField from '../../components/search-field';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// hooks
import useResponsive from '../../hooks/useResponsive';
// styles
import { SelectButton, GroupsPopover } from './styles';
// apis
import { getGroups } from '../../apis';

// ----------------------------------------------------------------------

const defaultValues = { content: [], number: 0, last: true, empty: true };

// ----------------------------------------------------------------------

export default function SearchGroups({ permission, pathname, setActive }) {
  const isDesktop = useResponsive('up', 'md');

  const navigate  = useNavigate();

  const storeGroup = useSelector((state) => state.group);
  
  const [ soilOriginal, setSoilOriginal ] = useState(defaultValues);
  const [ potentialOriginal, setPotentialOriginal ] = useState(defaultValues);
  
  const [ soil, setSoil ] = useState(defaultValues);
  const [ potential, setPotential ] = useState(defaultValues);

  const [ soilGroup, setSoilGroup ] = useState(null);
  const [ potentialGroup, setPotentialGroup ] = useState(null);

  const [ keyword, setKeyword ] = useState('');

  const [ visible, setVisible ] = useState(false);

  const buttonElement = useRef(null);
  const [ width, setWidth ] = useState(0);

  const [ observer ] = useState(
    new ResizeObserver((entries, observer) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setWidth(width);
      }
    })
  );
  useEffect(() => {
    if ( visible ) observer.observe(buttonElement.current);
    else observer.disconnect();
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if ( permission.soil ) {
      ( async () =>
        await getGroups(keyword, soil.number, 'soil')
          .then((response) => {
            setSoil(response);
            setSoilOriginal(response);
            if ( response.content.length > 0 ) {
              if ( storeGroup.soil ) return setSoilGroup(storeGroup.soil);
              return setSoilGroup(response.content[0]);
            }
          })
          .catch(() => {
            setSoil(defaultValues);
            setSoilGroup(null);
          })
      )();
    } else {
      setSoil(defaultValues);
      setSoilGroup(null);
    }

    if ( permission.potential ) {
      ( async () =>
        await getGroups(keyword, potential.number, 'potential')
          .then((response) => {
            setPotential(response);
            setPotentialOriginal(response);
            if ( response.content.length > 0 ) {
              if ( storeGroup.potential ) return setPotentialGroup(storeGroup.potential);
              return setPotentialGroup(response.content[0]);
            }
          })
          .catch(() => {
            setPotential(defaultValues);
            return setPotentialGroup(null);
          })
      )();
    } else {
      setPotential(defaultValues);
      return setPotentialGroup(null);
    }
    // eslint-disable-next-line
  }, [permission]);

  useEffect(() => {
    store.dispatch( SET_SOIL_GROUP(soilGroup) );
    // eslint-disable-next-line
  }, [soilGroup]);

  useEffect(() => {
    store.dispatch( SET_POTENTIAL_GROUP(potentialGroup) );
    // eslint-disable-next-line
  }, [potentialGroup]);

  const handleSearch = async (more) => {
    if ( more ) {
      await getGroups(keyword, more === 'soil' ? soil.number + 1 : potential.number + 1, more)
        .then((response) => {
          if ( more === 'soil' ) {
            const array = [ ...soil.content, ...response.content ];
            response.content = array;
            setSoil(response);
            setSoilOriginal(response);
          } else {
            const array = [ ...potential.content, ...response.content ];
            response.content = array;
            setPotential(response);
            setPotentialOriginal(response);
          }
        })
        .catch(() => {
          setPotential(defaultValues);
        });
    } else {
      await getGroups(keyword, 0, 'soil')
        .then((response) => {
          setSoil(response);
        })
        .catch(() => {
          setSoil(defaultValues);
        });
      await getGroups(keyword, 0, 'potential')
        .then((response) => {
          setPotential(response);
        })
        .catch(() => {
          setPotential(defaultValues);
        });
    }
  };

  const handleInitialize = () => {
    setSoil(soilOriginal);
    setPotential(potentialOriginal);
  };

  return (
    <>
      <SelectButton>
        <p>센서 그룹</p>
        <button ref={buttonElement} id='groups' onClick={() => setVisible(true)}>
          <span>
            { pathname === 'soil' ? (
              soilGroup?.name || '그룹 목록이 없습니다.'
            ) : (
              potentialGroup?.name || '그룹 목록이 없습니다.'
            )}
          </span>
          <div />
        </button>
      </SelectButton>

      <Popover
        target='#groups'
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
      >
        <SearchField
          value={keyword}
          onChange={setKeyword}
          handleSearch={handleSearch}
          handleInitialize={handleInitialize}
          placeholder='이름을 입력하세요.'
          width={ isDesktop ? 259 - 16 : width + 14 }
        />

        <GroupsPopover>
          { permission.soil &&
            soil.empty ? (
              <>
                <p>토양</p>
                <button disabled>그룹 목록이 없습니다.</button>
              </>
            ) : (
              <>
                <p>토양</p>
                { soil.content.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => {
                      setVisible(false);
                      setSoilGroup(item);
                      if ( !isDesktop ) setActive(false);
                      if ( pathname === 'potential' ) return navigate('soil/dashboard');
                    }}
                  >
                    { item.name }
                  </button>
                ))}

                { !soil.last &&
                  <button onClick={() => handleSearch('soil')}>더보기</button>
                }
              </>
            )
          }

          { permission.potential &&
            potential.empty ? (
              <>
                <p>부식 전위</p>
                <button disabled>그룹 목록이 없습니다.</button>
              </>
            ) : (
              <>
                <p>부식 전위</p>
                { potential.content.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => {
                      setVisible(false);
                      setPotentialGroup(item);
                      if ( !isDesktop ) setActive(false);
                      if ( pathname === 'soil' ) return navigate('potential/dashboard');
                    }}
                  >
                    { item.name }
                  </button>
                ))}

                { !potential.last &&
                  <button onClick={() => handleSearch('potential')}>더보기</button>
                }
              </>
            )
          }
        </GroupsPopover>
      </Popover>
    </>
  );
}