import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, dialog, setDialog, group }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  
  return (
    <tr>
      <td>{ row.number }</td>
      <td>{ row.serialNumber }</td>
      <td>{ row.latitude }</td>
      <td>{ row.longitude }</td>
      <td>{ row.address }</td>
      <td>{ row.detailAddress }</td>
      <td>{ row.enabled ? '활성화' : '비활성화' }</td>
      <td>{ row.createdAt }</td>
      <td>
        <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button
            onClick={() => navigate('update', {state: { group: group, sensor: row }})}
          >
            수정
          </button>

          <button
            onClick={() => {
              setDialog({ ...dialog,
                confirm: {
                  visible: true,
                  id: row.id,
                  title: '삭제',
                  type: 'delete'
                }
              });
              setVisible(false);
            }}
          >
            삭제
          </button>

          <button
            onClick={() => {
              setDialog({ ...dialog,
                confirm: {
                  visible: true,
                  id: row.id,
                  title: row.enabled ? '비활성화' : '활성화',
                  type: row.enabled ? 'disable' : 'enable'
                }
              });
              setVisible(false);
            }}
          >
            { row.enabled ? '비활성화' : '활성화' }
          </button>

          <button
            onClick={() => {
              setDialog({ ...dialog,
                form: {
                  visible: true,
                  id: row.id,
                  serialNumber: row.serialNumber
                }
              });
            }}
          >
            일련 번호 수정
          </button>

          <button
            onClick={() => navigate('potential-channel', {state: { group: group, sensor: row }})}
          >
            부식 전위 채널 관리
          </button>

        </Popover>
      </td>
    </tr>
  );
}