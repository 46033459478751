import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, setDialog, group, testCase }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  
  return (
    <tr>
      <td>{ row.number }</td>
      <td>{ row.material }</td>
      <td>{ row.point }</td>
      <td>{ row.sensorNumber }</td>
      <td>{ row.channel }</td>
      <td>{ row.description }</td>
      <td>
        <TableMoreButton id={`more${row.channelId}`} onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.channelId}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button
            onClick={() => navigate('update', {state: { group: group, testCase: testCase, specimen: row }})}
          >
            수정
          </button>

          <button
            onClick={() => {
              setDialog({
                visible: true,
                id: row.channelId
              });
              setVisible(false);
            }}
          >
            삭제
          </button>

        </Popover>
      </td>
    </tr>
  );
}