import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_SPECIAL } from '../../../utils/regexp';
// styles
import { WithButton, Message } from '../../../styles';
// apis
import { checkCompanyName, createCompanies, updateCompanies } from '../../../apis';

// ----------------------------------------------------------------------

  const schema = yup.object().shape({
    name: yup.string()
      .required()
      .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
      .max(30, '30자 이내로 입력해주세요.')
  });

// ----------------------------------------------------------------------

export default function CompanyForm({ dialog, setDialog, handleReset }) {

  const { title, type, state } = dialog.form;

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: state?.name || ''
    }
  });

  const [ checked, setChecked ] = useState({ result: null, name: null });
  const [ message, setMessage ] = useState({ checked: null, error: null });

  const onSubmit = async (data, event) => {
    if ( event ) { // 중복 체크
      return await checkCompanyName(data.name)
        .then((response) => {
          setChecked({ result: !response, name: data.name });
          setMessage({ ...message, checked: !response ? '사용하실 수 있는 기업명입니다.' : '이미 등록된 기업명입니다.' });
        })
        .catch((error) => {
          setMessage({ ...message, checked: error.message });
        });
    }

    if ( checked.result === null || checked.name !== data.name ) {
      setChecked({ result: null, name: null });
      setMessage({ ...message, checked: '중복 체크 후 진행하실 수 있습니다.' });
    } else if ( checked.result ) {
      if ( type === 'create' ) {
        await createCompanies(data.name)
          .then(() => {
            handleReset();
            setDialog({ ...dialog,
              form: { visible: false }
            });
          })
          .catch((error) => {
            setMessage({ ...message, error: '기업을 등록할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      } else {
        await updateCompanies(state.id, data.name)
          .then(() => {
            handleReset();
            setDialog({ ...dialog,
              form: { visible: false }
            });
          })
          .catch(() => {
            setMessage({ ...message, error: '기업을 수정할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      }
    }
  };

  return (
    <Dialog
      title={ `기업 ${title}` }
      message={message.error && message.error}
      actions={
        <div>
          <button onClick={() => handleSubmit((data, event) => onSubmit(data, event))()} disabled={Object.keys(errors).length > 0 ? true : false}>
            { title }
          </button>
          <button onClick={() => {
            setDialog({ ...dialog,
              form: { visible: false }
            });
          }}>
            취소
          </button>
        </div>
      }
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <WithButton>
          <div>
            <TextField
              type='text'
              name='name'
              control={control}
              placeholder='기업명을 입력하세요.'
            />
            <button>중복 체크</button>
          </div>
        </WithButton>
        { message.checked &&
          <Message $success={ checked.result }>{ message.checked }</Message>
        }
      </form>
    </Dialog>
  );
}