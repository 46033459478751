import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getRegisteredPotentials = async (setData, companyId, name, page, size) => {
  return await axios.get(`company-potentials/companies/${companyId}/registered/search-page?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getRegisteredPotentials;