import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialDashboards = async (groupId) => {
  return await axios.get(`potential-dashboards/groups/${groupId}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getPotentialDashboards;