function decode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export default function validation(token) {
  if ( !token ) return false;

  const decoded = decode(token);
  const currentTime = Math.floor(Date.now() / 1000) + 60;
  
  return decoded.exp > currentTime;
};