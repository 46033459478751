import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialAnalysis = async (setData, testCaseId, date, type) => {

  const year = date.getFullYear();
  const month = String(date.getMonth()+1).padStart(2, '0');
  
  let url = `potential-data-analyses/monthly/test-cases/${testCaseId}?searchDate=${year}-${month}`;
  if ( type === 'yearly') url = `potential-data-analyses/yearly/test-cases/${testCaseId}?searchDate=${year}`;

  return await axios.get(url)
    .then((response) => {
      if ( response.data.data.result.length > 0 ) return setData(response.data.data.result);
      setData([{ material: '', point: '', data: [] }]);
    })
    .catch(() => {
      setData([{ material: '', point: '', data: [] }]);
    });
};

export default getPotentialAnalysis;