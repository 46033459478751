import { useNavigate, Outlet } from 'react-router-dom';
// store
import { useSelector } from '../../store';
// components
import Button from '../../components/button';
// styles
import { Inner, Header, Logo, Main, Footer } from './styles';
// apis
import { getAccounts } from '../../apis';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const navigate  = useNavigate();

  const auth = useSelector(state => state.auth);
  const account = useSelector(state => state.account);

  const handleEvents = async () => {
    if ( auth ) {
      let permission = account?.permission;
      if ( !account ) {
        await getAccounts()
          .then((response) => {
            permission = response.permission;
          })
          .catch(() => {
            return navigate('/login');
          });
      }
      let path = '/soil';
      if ( permission === 0 ) return navigate('/login');
      if ( permission === 2 ) return path = '/potential';
      return navigate(`${ path }/dashboard`, {state: {submit: true}});
    }
    navigate('/login');
  };

  return (
    <>
      <Header>
        <Inner>
          <Logo />
          <Button label='로그인' onClick={() => handleEvents()} />
        </Inner>
      </Header>

      <Main>
        <Outlet />
      </Main>

      <Footer>
        <Inner>
          <p>
            <span>사업자등록번호</span> | 590 87 01857<br />
            <span>대표이사</span> | 이재빈, 황원식<br />
            <span>주 소</span> | 충청북도 청주시 흥덕구 직지대로 436번길 76, 1128-1130호 (송정동, 직지스타)
          </p>
          <p>©STS Engineering Corp. All Rights Reserved.</p>
        </Inner>
      </Footer>
    </>
  );
}