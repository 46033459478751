import { useState, useEffect } from 'react';
// prop-types
import PropTypes from 'prop-types';
// styles
import { Wrapper, SearchButton, DeleteButton } from './styles';

// ----------------------------------------------------------------------

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

// ----------------------------------------------------------------------

export default function SearchField({ value, onChange, handleSearch, handleInitialize, width, ...props }) {

  const [ initialize, setInitialize ] = useState(true);

  useEffect(() => {
    if ( value.length === 0 && !initialize ) {
      handleInitialize();
      setInitialize(true);
    }
    // eslint-disable-next-line
  }, [value]);

  const onSearch = () => {
    if ( value.length > 0 && value.replace(/ /g, '').length === 0 ) return;
    if ( value.length === 0 ) {
      handleInitialize();
      setInitialize(true);
    } else {
      handleSearch();
      setInitialize(false);
    }
  };

  return (
    <Wrapper $value={ value.length > 0 && true } $width={width}>
      <div>
        <input
          type='text'
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onSearch();
          }}
          { ...props }
        />
        { value.length > 0 && value.replace(/ /g, '').length !== 0 &&
          <DeleteButton onClick={() => onChange('')} />
        }
      </div>
      <SearchButton onClick={() => onSearch()} />
    </Wrapper>
  );
};