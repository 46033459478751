import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updatePotentialSensors = async (sensorId, data) => {
  return await axios.put(`potential-sensors/${sensorId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updatePotentialSensors;