export const REGEXP_EMAIL = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
// eslint-disable-next-line
export const REGEXP_PASSWORD = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\~\!\@\#\$\%\^\&\*\(\)\+\|\=])[a-zA-Z0-9\~\!\@\#\$\%\^\&\*\(\)\+\|\=]{8,16}$/;

export const REGEXP_LETTER = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]*$/;
// eslint-disable-next-line
export const REGEXP_SPECIAL = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\!\@\&\(\)\-\_\+\[\]\{\}\.\s]*$/;

export const REGEXP_PHONE = /^(070|02|0[3-9]{1}[0-9]{1}|01[016789]{1})-[0-9]{3,4}-[0-9]{4}$/;

export const REGEXP_NUMBER = /^[0-9]*$/;