import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deleteCompanies = async (companyId) => {
  return await axios.delete(`companies/${companyId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deleteCompanies;