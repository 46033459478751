import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const registerPotentials = async (companyId, groupIds) => {
  return await axios.post('company-potentials ', {companyId, groupIds})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default registerPotentials;