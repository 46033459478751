import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deleteSoilGroups = async (groupId) => {
  return await axios.delete(`soil-groups/${groupId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deleteSoilGroups;