import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const decode = (disposition) => {
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, '')
  );
  return fileName.normalize('NFC');
};

// ----------------------------------------------------------------------

const downloadSoilAnalysis = async (sensorId, date, type) => {

  const year = date.getFullYear();
  const month = String(date.getMonth()+1).padStart(2, '0');
  
  let request = `soil-data-analyses/monthly/sensors/${sensorId}/download?searchDate=${year}-${month}`;
  if ( type === 'yearly') request = `soil-data-analyses/yearly/sensors/${sensorId}/download?searchDate=${year}`;

  return await axios.get(request, { responseType: 'blob' })
    .then((response) => {
      const url = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;
      link.style.display = 'none';

      link.download = decode(response.headers['content-disposition']);

      document.body.appendChild(link);
      link.click();
      link.remove();
      
      URL.revokeObjectURL(url);
    })
    .catch(() => {});
};

export default downloadSoilAnalysis;