import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getSoilAnalysis = async (setData, sensorId, date, type) => {

  const year = date.getFullYear();
  const month = String(date.getMonth()+1).padStart(2, '0');
  
  let url = `soil-data-analyses/monthly/sensors/${sensorId}?searchDate=${year}-${month}`;
  if ( type === 'yearly') url = `soil-data-analyses/yearly/sensors/${sensorId}?searchDate=${year}`;

  return await axios.get(url)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {
      setData([]);
    });
};

export default getSoilAnalysis;