import { useState, useEffect } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { TagWrapper } from '../../../styles';
// apis
import { getUnregisteredPotentials, registerPotentials } from '../../../apis';

// ----------------------------------------------------------------------

export default function Form({ dialog, setDialog, handleReset }) {

  const companyId = dialog.form.id;

  const [ groups, setGroups ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = groups;
  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);

  const [ tags, setTags ] = useState([]);
  const [ selected, setSelected ] = useState([]);

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    ( async () =>
      await getUnregisteredPotentials(setGroups, companyId, keyword, page)
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setPage(0);
    getUnregisteredPotentials(setGroups, companyId, keyword, 0);
  };

  const handlePage = (value) => {
    setPage(value - 1);
    getUnregisteredPotentials(setGroups, companyId, keyword, value-1);
  };

  useEffect(() => {
    setSelected(tags.map((tag) => { return tag.id }));
  }, [tags]);
  
  const handleChecked = (checked, data) => {
    if ( checked ) {
      setTags((current) => [ ...current, data ]);
    } else {
      handleUnchecked(data);
    }
  };
  
  const handleUnchecked = (data) => setTags(tags.filter((tag) => JSON.stringify(tag) !== JSON.stringify(data) ));

  const handleAction = async () => {
    if ( selected.length === 0 ) return setMessage('그룹을 선택해 주세요.');

    await registerPotentials(companyId, selected)
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          form: { visible: false }
        });
      })
      .catch(() => {
        setMessage('부식 전위 그룹을 설정할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  return (
    <Dialog
      title='부식 전위 그룹 추가'
      message={ message }
      actions={
        <div>
          <button onClick={() => handleAction()} disabled={selected.length === 0 ? true : false}>
            적용
          </button>
          <button onClick={() => {
            setDialog({ ...dialog,
              form: { visible: false }
            });
          }}>
            취소
          </button>
        </div>
      }
    >

      <SearchField
        value={keyword}
        onChange={setKeyword}
        handleSearch={handleSearch}
        handleInitialize={handleSearch}
        placeholder='그룹명을 입력하세요.'
      />

      <DataGrid
        dataSource={rows}
        noDataText=''
        columnAutoWidth={true}
        dataRowRender={({data}) => <TableRow row={data} selected={selected} handleChecked={handleChecked} />}
        elementAttr={{class: 'datagrid-checkbox'}}
      >
        <Column />
        <Column caption='그룹' dataField='name' alignment='center' />
        <Column caption='설명' dataField='description' alignment='center' />
      </DataGrid>

      <Pagination page={page} pages={pages} handlePage={handlePage} />

      { tags.length > 0 &&
        <TagWrapper>
          { tags.map((tag) => (
            <button
              key={tag.id}
              onClick={() => handleUnchecked(tag)}
            >
              <p>{ tag.name }</p>
              <div />
            </button>
          )) }
        </TagWrapper>
      }

    </Dialog>
  );
}

function TableRow({ row, selected, handleChecked}) {
  return (
    <tr>
      <td>
        <label htmlFor={`checkbox${row.id}`}>
          <input
            type='checkbox'
            id={`checkbox${row.id}`}
            checked={selected.includes(row.id)}
            onChange={(event) => handleChecked(event.target.checked, row)}
          />
          <label htmlFor={`checkbox${row.id}`} />
        </label>
      </td>
      <td><label htmlFor={`checkbox${row.id}`}>{ row.name }</label></td>
      <td><label htmlFor={`checkbox${row.id}`}>{ row.description }</label></td>
    </tr>
  );
}