import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, setDialog }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  
  return (
    <>
      <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.id}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >

        <button
          onClick={() => navigate('update', {state: row})}
        >
          수정
        </button>

        <button
          onClick={() => {
            setDialog({
              visible: true,
              id: row.id
            });
            setVisible(false);
          }}
        >
          삭제
        </button>

        <button
          onClick={() => navigate('potential-sensor', {state: { group: row }})}
        >
          부식 전위 센서 관리
        </button>

        <button
          onClick={() => navigate('test-case', {state: { group: row }})}
        >
          테스트 케이스 관리
        </button>

      </Popover>
    </>
  );
}