import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// store
import { useSelector } from '../../store';
//components
import TextField from '../../components/text-field';
import Button from '../../components/button';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_PASSWORD  } from '../../utils/regexp';
// styles
import { Message, NextButton } from '../../styles';
// apis
import { changePassword, changeExpiration, logout } from '../../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  current: yup.string()
    .required()
    .min(8)
    .max(16)
    .matches(REGEXP_PASSWORD),
  new: yup.string()
    .required()
    .min(8, '8 - 16자 이내로 입력해주세요.')
    .max(16, '8 - 16자 이내로 입력해주세요.')
    .matches(REGEXP_PASSWORD, '비밀번호 형식을 확인해 주세요.'),
  confirm: yup.string()
    .required()
    .oneOf([yup.ref('new'), null], '비밀번호가 일치하지 않습니다.')
});

// ----------------------------------------------------------------------

export default function PasswordForm() {

  const navigate  = useNavigate();

  const location  = useLocation();
  const account  = location.pathname === '/account';
  const expiration = location.state?.expiration;
  const temp = location.state?.temp;

  const permission = useSelector(state => state.account?.permission);
  
  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      current: '',
      new: '',
      confirm: ''
    }
  });

  const [ alert, setAlert ] = useState(null);

  const onSubmit = async (data) => {
    await changePassword(data.current, data.new, temp)
      .then( async () => {
        if ( account ) return navigate(-1);
        return await logout('passwords')
          .then(() => navigate('/login'));
      })
      .catch((error) => setAlert(error.message));
  };

  const handleNext = async () => {
    await changeExpiration()
      .then(() => {
        return navigate(`${ permission === 2 ? '/potential' : '/soil' }/dashboard`, {state: {submit: true}});
      })
      .catch((error) => setAlert(error.message));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type='password'
        name='current'
        control={control}
        placeholder='현재 비밀번호'
        autoComplete='off'
        $exception
      />

      <TextField
        name='new'
        control={control}
        placeholder='신규 비밀번호'
        autoComplete='off'
        $password
      />

      <TextField
        name='confirm'
        control={control}
        placeholder='신규 비밀번호 확인'
        autoComplete='off'
        $password
      />

      <Message $info>8-16자·영문·숫자·특수문자~!@#$%^&*()+|=</Message>

      <Message>{ alert }</Message>  

      <Button label='확인' />

      { expiration &&
        <NextButton type='button' onClick={() => handleNext()}>다음에 변경</NextButton>
      }
    </form>
  );
}