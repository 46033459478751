import axios from '../_utils/axios';
import store, { SET_ACCOUNT } from '../../store';

// ----------------------------------------------------------------------

const getAccounts = async () => {
  return await axios.get('accounts/me')
    .then((response) => {
      const account = response.data.data;
      account.permission = account.groupPermission;
      delete account.groupPermission;
      store.dispatch( SET_ACCOUNT(account) );
      return account;
    })
    .catch(() => {});
};

export default getAccounts;