import axios from '../../_utils/axios';
import message from '../../../utils/message';

// ----------------------------------------------------------------------

const checkSpecimensNumber = async (testCaseId, number) => {
  return await axios.get(`specimens/test-cases/${testCaseId}/numbers/${number}/exists`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch(() => {
      return Promise.reject(
        new Error(
          message('COMMON', 'default')
        )
      );
    });
};

export default checkSpecimensNumber;