import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialChannels = async (setData, sensorId) => {
  return await axios.get(`potential-sensors/${sensorId}/channels`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPotentialChannels;