import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, dialog, setDialog, group }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  
  return (
    <tr>
      <td>{ row.number }</td>
      <td>{ row.name }</td>
      <td>{ row.createdAt }</td>
      <td>
        <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >

          <button
            onClick={() => {
              setDialog({ ...dialog,
                form: {
                  visible: true,
                  id: group.id,
                  state: row,
                  type: 'update'
                }
              });
              setVisible(false);
            }}
          >
            수정
          </button>

          <button
            onClick={() => {
              setDialog({ ...dialog,
                delete: {
                  visible: true,
                  id: row.id
                }
              });
              setVisible(false);
            }}
          >
            삭제
          </button>

          <button
            onClick={() => navigate('specimen', {state: { group: group, testCase: row }})}
          >
            시편 관리
          </button>

        </Popover>
      </td>
    </tr>
  );
}