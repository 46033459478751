import axios from 'axios';
import message from '../../utils/message';
import store, { SET_AUTH, SET_TOKEN, SET_ACCOUNT } from '../../store';

// ----------------------------------------------------------------------

const codes = ['ERR-022', 'ERR-023'];

// ----------------------------------------------------------------------

const login = async ( email, password ) => {
  return await axios.post('/api/auth/login', { email, password })
    .then( async (response) => {
      const data = response.data.data;
      const token = data.token.accessToken;
      store.dispatch( SET_AUTH() );
      store.dispatch( SET_TOKEN(token) );

      const accounts = await axios.get('/api/accounts/me', {headers: {Authorization: `Bearer ${token}`}});
      const account = accounts.data.data;
      account.permission = account.groupPermission;
      delete account.groupPermission;
      store.dispatch( SET_ACCOUNT(account) );

      return {
        temp: data.tempPassword,
        expiration: data.passwordExpiration,
        permission: account.permission,
        admin: account.role === '관리자' ? true : false
      };
    })
    .catch((error) => {
      const code = error.response.data.code;
      return Promise.reject(
        new Error(
          message('LOGIN', codes.includes(code) ? code : 'default')
        )
      );
    });
};

export default login;