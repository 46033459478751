import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// sections
import TableRow from './TableRow';
import SerialNumberForm from './SerialNumberForm';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// utils
import { REGEXP_NUMBER } from '../../../utils/regexp';
// styles
import { Content, Box, AffiliatedTitle, SearchWrapper, AddButton } from '../../../styles';
// apis
import { getPotentialSensors, deletePotentialSensors, enablePotentialSensors, disablePotentialSensors } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

// ----------------------------------------------------------------------

export default function PotentialSensoFormPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const group = state?.group;

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('number');
  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);
  
  const [ dialog, setDialog ] = useState({
    confirm: { visible: false },
    form: { visible: false }
  });

  const [ message, setMessage ] = useState({ confirm: null, form: null });

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getPotentialSensors(setData, group?.id, search(), page, size)
    )();
    // eslint-disable-next-line
  }, [state]);

  const search = () => {
    return {
      number : type === 'number' ? keyword : '',
      serialNumber: type === 'serialNumber' ? keyword : ''
    }
  };

  const handlePage = (value) => {
    setPage(value - 1);
    getPotentialSensors(setData, group.id, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getPotentialSensors(setData, group.id, search(), 0, Number(event.target.value));
  };

  const handleSearch = () => {
    if ( type === 'number' && !REGEXP_NUMBER.test(keyword) ) return;
    setPage(0);
    getPotentialSensors(setData, group.id, search(), 0, size);
  };

  const handleAction = async () => {
    await actions()
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          confirm: { visible: false }
        });
      })
      .catch(() => {
        setMessage({ ...message, confirm: `부식 전위 센서를 ${ dialog.confirm.title }할 수 없습니다. 나중에 다시 시도해 주세요.` });
      });
  };

  const actions = async () => {
    const id = dialog.confirm.id;
    // eslint-disable-next-line
    switch ( dialog.confirm.type ) {
      case 'delete':
        return await deletePotentialSensors(id);
      case 'enable':
        return await enablePotentialSensors(id);
      case 'disable':
        return await disablePotentialSensors(id);
    }
  };

  const handleReset = () => {
    if ( dialog.confirm.type !== 'delete' ) return getPotentialSensors(setData, group.id, search(), page, size);
    handleSearch();
  };

  if ( !state ) return;

  return (
    <Content>
      <Breadcrumbs
        heading='부식 전위 센서 관리'
        links={[
          { name: '시스템 관리' },
          { name: '부식 전위 그룹 관리', link: '/system/potential-group' },
          { name: '부식 전위 센서 관리' }
        ]}
      />

      <Box>
        <AffiliatedTitle>
          <p>{ group?.name }</p>
          <span>{ group?.description }</span>
        </AffiliatedTitle>

        <SearchWrapper>
          <div>
            <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
              <option value='number'>장비 번호</option>
              <option value='serialNumber'>일련번호</option>
            </select>

            <SearchField
              value={keyword}
              onChange={setKeyword}
              handleSearch={handleSearch}
              handleInitialize={handleSearch}
              placeholder='검색어를 입력하세요.'
            />
          </div>

          <AddButton onClick={() => navigate('create', {state: { group : group }})}>
            등록
          </AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          dataRowRender={({data}) => <TableRow row={data} dialog={dialog} setDialog={setDialog} group={group} />}
        >
          <Column caption='장비 번호' dataField='number' alignment='center' />
          <Column caption='일련 번호' dataField='serialNumber' alignment='center' />
          <Column caption='위도' dataField='latitude' alignment='center' />
          <Column caption='경도' dataField='longitude' alignment='center' />
          <Column caption='주소' dataField='address' alignment='center' />
          <Column caption='상세 주소' dataField='detailAddress' alignment='center' />
          <Column caption='활성 상태' dataField='enabled' alignment='center' />
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

        { dialog.confirm.visible &&
          <Dialog
            title={ `부식 전위 센서 ${dialog.confirm.title}` }
            icon={ dialog.confirm.type === 'delete' && dialog.confirm.type }
            message={message.confirm}
            actions={
              <div>
                <button onClick={() => handleAction()}>
                  { dialog.confirm.title }
                </button>
                <button onClick={() => {
                  setDialog({ ...dialog,
                    confirm: { visible: false }
                  });
                  setMessage({ ...message,
                    confirm: null
                  });
                }}>
                  취소
                </button>
              </div>
            }
          >
            <p>선택하신 부식 전위 센서를 { dialog.confirm.title }하시겠습니까?</p>
          </Dialog>
        }

        { dialog.form.visible &&
          <SerialNumberForm dialog={dialog} setDialog={setDialog} handleReset={handleSearch} />
        }
        
      </Box>
    </Content>
  );
}