import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getWeathers = async (weatherId) => {
  return await axios.get(`weathers/${weatherId}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getWeathers;