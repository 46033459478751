import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deletePotentials = async (companyId, groupId) => {
  return await axios.delete(`company-potentials/companies/${companyId}/groups/${groupId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deletePotentials;