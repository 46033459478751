import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
// styles
import { Label, Select, Input } from '../../../styles';
// apis
import { createChannels } from '../../../apis';

// ----------------------------------------------------------------------

export default function Form({ dialog, setDialog, sensor, channels, handleReset }) {

  const [ channel, setChannel ] = useState(channels.length > 0 ? channels[0] : undefined);
  const [ message, setMessage ] = useState(null);
  const [ description, setDescription ] = useState('');
  const [ error, setError ] = useState(null);

  const onSubmit = async () => {
    if ( !channel ) return setMessage('채널을 선택해 주세요.');
    const data = { sensorId: sensor, channel, description };
    if ( description.replace(/ /g, '').length === 0 ) delete data.description;

    await createChannels(data)
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          form: { visible: false }
        });
      })
      .catch((error) => {
        if ( error.response.status === 409 ) return setError(true);
        setMessage('채널을 추가할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  return (
    <Dialog
      title='부식 전위 채널 추가'
      message={message}
      actions={
        !error ? (
          <div>
            <button onClick={() => onSubmit()} disabled={channel ? false : true}>
              추가
            </button>
            <button onClick={() => {
              setDialog({ ...dialog,
                form: { visible: false }
              });
            }}>
              취소
            </button>
          </div>
        ) : (
          <button onClick={() => {
            setDialog({ ...dialog,
              form: { visible: false }
            });
            handleReset(true);
          }}>
            확인
          </button>
        )
      }
    >
      { !error ? (
        <>
          <Label>채널</Label>
          <Select onChange={(event) => setChannel(event.target.value)}>
            { channels.length > 0 ? (
              channels.map((item) => (
                <option key={item} value={item}>{ item }</option>
              ))
            ) : (
              <option>추가 가능한 채널이 없습니다.</option>
            )}
          </Select>

          <Label>설명</Label>
          <Input type='text' onChange={(event) => setDescription(event.target.value)} />
        </>
      ) : (
        <p>이미 등록되어 있는 채널입니다.</p>
      )}
    </Dialog>
  );
}