import axios from 'axios';
import store, { persistor, SET_LOADING, DELETE_LOADING, DELETE_TOKEN, DELETE_ACCOUNT, DELETE_GROUP } from '../../store';
import validation from './validation';
import reissue from '../auth-account/reissue';
import logout from '../auth-account/logout';

// ----------------------------------------------------------------------

const instance = axios.create({
  baseURL: '/api/',
  timeout: 5000
});

instance.interceptors.request.use( async (config) => {

  const auth = store.getState().auth;
  if ( !auth ) return window.location.href = '/';
  
  store.dispatch( SET_LOADING() );

  const token = store.getState().token;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  if ( !validation(token) ) await reissue(config);
  return config;
});

instance.interceptors.response.use(
  (response) => {
    store.dispatch( DELETE_LOADING() );
    return response;
  },
  async (error) => {
    store.dispatch( DELETE_LOADING() );

    if ( error.response.data.code === 'ERR-001' ) {
      await logout()
        .catch(() => {
          store.dispatch( DELETE_TOKEN() );
          store.dispatch( DELETE_ACCOUNT() );
          store.dispatch( DELETE_GROUP() );
          persistor.purge();
          return window.location.href = '/';
        });
    }
    return Promise.reject(error);
  }
);

export default instance;