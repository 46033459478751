import { useState, useEffect } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// apis
import { searchMembers, settingPic } from '../../../apis';

// ----------------------------------------------------------------------

export default function PicForm({ dialog, setDialog, handleReset }) {

  const { id, name } = dialog.pic;


  const [ members, setMembers ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = members;
  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);

  const [ select, setSelect ] = useState(null);

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    ( async () =>
      await searchMembers(setMembers, id, keyword, page)
        .then((response) => {
          if ( name ) {
            const result = response.filter((item) => item.name === name )[0];
            if ( result ) {
              setSelect(result.id);
            }
          }
        })
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setPage(0);
    searchMembers(setMembers, id, keyword, 0);
  };

  const handlePage = (value) => {
    setPage(value - 1);
    searchMembers(setMembers, id, keyword, value-1);
  };

  const handlePic = async () => {
    await settingPic(id, select)
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          pic: { visible: false }
        });
      })
      .catch(() => {
        setMessage('담당자를 설정할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  return (
    <Dialog
      title='담당자 설정'
      message={ message }
      actions={
        <div>
          <button
            onClick={() => {
              if ( !select ) return setMessage('담당자를 선택해주세요.');
              handlePic();
            }}
            disabled={ !select ? true : false}
          >
            적용
          </button>
          <button onClick={() => {
            setDialog({ ...dialog,
              pic: { visible: false }
            });
          }}>
            취소
          </button>
        </div>
      }
    >

      <SearchField
        value={keyword}
        onChange={setKeyword}
        handleSearch={handleSearch}
        handleInitialize={handleSearch}
        placeholder='이름을 입력하세요.'
      />

      <DataGrid
        dataSource={rows}
        noDataText=''
        columnAutoWidth={true}
        dataRowRender={({data}) => <TableRow row={data} select={select} setSelect={setSelect} />}
      >
        <Column />
        <Column caption='이름' dataField='name' alignment='center' />
        <Column caption='소속' dataField='affiliation' alignment='center' />
      </DataGrid>

      <Pagination page={page} pages={pages} handlePage={handlePage} />

    </Dialog>
  );
}

function TableRow({ row, select, setSelect }) {
  return (
    <tr onClick={() => setSelect(row.id)}>
      <td>
        <input type='radio' name='members' checked={ select && select === row.id ? true : false } readOnly />
      </td>
      <td>{ row.name }</td>
      <td>{ row.affiliation }</td>
    </tr>
  );
}