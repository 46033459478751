import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const enablePotentialSensors = async (sensorId) => {
  return await axios.put(`potential-sensors/${sensorId}/enable `)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default enablePotentialSensors;