import styled from 'styled-components';

// ----------------------------------------------------------------------

export const ButtonContainer = styled.button`
  width: 100%;
  display: block;
  height: 45px;
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: 2.5px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.primary.main };
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark };
  }

  &:disabled {
    color: #8F8F8F;
    background-color: #DDDDDD;
  }
`;

export const RippleStyled = styled.span`
  @keyframes rippleEffect {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  position: absolute;
  opacity: 30%;
  transform: scale(0);
  animation: rippleEffect 0.6s linear;
  border-radius: 50%;
`;