import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updatePotentialSerialNumber = async (sensorId, serialNumber) => {
  return await axios.put(`potential-sensors/${sensorId}/serial-number`, {serialNumber})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updatePotentialSerialNumber;