import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updatePotentialGroups = async (groupId, data) => {
  return await axios.put(`potential-groups/${groupId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updatePotentialGroups;