import PropTypes from 'prop-types';

import { Wrapper, Button } from './styles';

// ----------------------------------------------------------------------

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  tab: PropTypes.string,
  handleTab: PropTypes.func
};

// ----------------------------------------------------------------------

export default function Tab({ tabs, tab, handleTab }) {
  return (
    <Wrapper>
      { tabs.map((item) => (
        <Button key={item.value} onClick={() => handleTab(item.value)} $active={tab === item.value && true}>{ item.label }</Button>
      ))}
    </Wrapper>
  );
}