import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const createSoilGroups = async (data) => {
  return await axios.post('soil-groups ', data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createSoilGroups;