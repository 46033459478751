import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getSoilSensorList = async (setData, groupId) => {
  return await axios.get(`groups/soil-groups/${groupId}/sensors`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getSoilSensorList;