// sections
import PasswordForm from './PasswordForm';
// styles
import { FormContent, AccountTitle } from '../../styles';

// ----------------------------------------------------------------------

export default function ChangePasswordPage() {

  return (
    <FormContent>
      <AccountTitle>
        <p>비밀번호 변경 안내</p>
        <span>
          개인 정보 보호를 위하여 비밀번호 변경을 안내해 드리고 있습니다.<br />
          안전한 비밀번호로 변경 부탁드립니다.
        </span>
      </AccountTitle>

      <PasswordForm />
    </FormContent>
  );
}