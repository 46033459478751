import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

export const auth = createSlice({
  name: 'auth',
  initialState: null,
  reducers: {
    SET_AUTH: () => {
      return true;
    }
  }
});

export const { SET_AUTH } = auth.actions;

// ----------------------------------------------------------------------

export const token = createSlice({
  name: 'token',
  initialState: null,
  reducers: {
    SET_TOKEN: (state, action) => {
      return action.payload;
    },
    DELETE_TOKEN: () => {
      return null;
    }
  }
});

export const { SET_TOKEN, DELETE_TOKEN } = token.actions;

// ----------------------------------------------------------------------

export const account = createSlice({
  name: 'account',
  initialState: null,
  reducers: {
    SET_ACCOUNT: (state, action) => {
      return action.payload;
    },
    DELETE_ACCOUNT: () => {
      return null;
    }
  }
});

export const { SET_ACCOUNT, DELETE_ACCOUNT } = account.actions;

// ----------------------------------------------------------------------

export const group = createSlice({
  name: 'group',
  initialState: {
    soil: null,
    potential: null
  },
  reducers: {
    SET_SOIL_GROUP: (state, action) => {
      return {
        ...state,
        soil: action.payload
      };
    },
    SET_POTENTIAL_GROUP: (state, action) => {
      return {
        ...state,
        potential: action.payload
      };
    },
    DELETE_GROUP: () => {
      return null;
    }
  }
});

export const { SET_SOIL_GROUP, SET_POTENTIAL_GROUP, DELETE_GROUP } = group.actions;

// ----------------------------------------------------------------------

export const loading = createSlice({
  name: 'loading',
  initialState: null,
  reducers: {
    SET_LOADING: () => {
      return true;
    },
    DELETE_LOADING: () => {
      return null;
    }
  }
});

export const { SET_LOADING, DELETE_LOADING } = loading.actions;