import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../store';
// components
import Breadcrumbs from '../../components/breadcrumbs';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import Chart, { Series, CommonSeriesSettings, Point, ArgumentAxis, Grid, Legend, Tooltip, Crosshair, HorizontalLine, CommonAxisSettings, ValueAxis, Label } from 'devextreme-react/chart';
// styles
import { Content, Box, SearchWrapper, Tabs, Tab, SearchButton, DownloadButton, AnalysisChart } from '../../styles';
// apis
import { getTestCasesList, getPotentialAnalysis, downloadPotentialAnalysis } from '../../apis';

// ----------------------------------------------------------------------

const current = new Date();
const minDate = new Date(current);
minDate.setFullYear(2000);

// ----------------------------------------------------------------------

export default function PotentialAnalysisPage() {

  const group = useSelector(state => state.group.potential);

  const [ testCases, setTestCases ] = useState([]);
  const [ testCase, setTestCase ] = useState('none');

  const [ tab, setTab ] = useState('monthly');

  const [ date, setDate ] = useState(current);

  const [ data, setData ] = useState([{ material: '', point: '', data: [] }]);

  useEffect(() => {
    if ( group ) {
      ( async () =>
        await getTestCasesList(setTestCases, group.id)
      )();
    }
  }, [ group ]);

  useEffect(() => {
    if ( testCases.length > 0 ) {
      setTestCase(testCases[0].id);
      ( async () =>
        await getPotentialAnalysis(setData, testCases[0].id, date, tab)
      )();
    } else {
      setTestCase('none');
      setData([{ material: '', point: '', data: [] }]);
    }
    // eslint-disable-next-line
  }, [ testCases ]);

  const handleSearch = async () => await getPotentialAnalysis(setData, testCase, date, tab);

  const handleDownload = async () => {
    if ( group ) {
      await downloadPotentialAnalysis(testCase, date, tab);
    }
  };

  const customizeTooltip = (info) => {
    return (
      <div style={{color: '#FFFFFF', fontSize: '12px', letterSpacing: '1px', textAlign: 'center', lineHeight: '160%'}}>
        { info.argument }<br />
        { info.value } mV
      </div>
    );
  };

  const customizeLabel = (e) => {
    return `${e.valueText} mV`
  };

  return (
    <Content>
      <Breadcrumbs
        heading='데이터 분석'
        links={[
          { name: '부식 전위' },
          { name: '데이터 분석' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <div>
            <select value={testCase.id} onChange={(event) => setTestCase(event.target.value)}>
              { testCase === 'none' ? (
                <option value={'none'}>테스트 케이스 목록이 없습니다.</option>
              ) : (
                testCases.map((item) => (
                  <option key={item.id} value={item.id}>{ item.name }</option>
                ))
              )}
            </select>

            <Tabs>
              <Tab onClick={() => setTab('monthly')} $active={tab === 'monthly' && true}>월간</Tab>
              <Tab onClick={() => setTab('yearly')} $active={tab === 'yearly' && true}>연간</Tab>
            </Tabs>

            <DateBox
              type='date'
              value={date}
              elementAttr={{ class: 'small' }}
              onValueChanged={(event) => setDate(event.value)}
              displayFormat={ tab === 'monthly' ? 'yyyy.MM' : 'yyyy' }
              calendarOptions={{
                maxZoomLevel: tab === 'monthly' ? 'year' : 'decade',
                minZoomLevel: 'century'
              }}
              openOnFieldClick={true}
              useMaskBehavior={true}
              min={minDate}
              max={current}
              pickerType='calendar'
            />

            <SearchButton onClick={() => handleSearch() }>검색</SearchButton>
          </div>

          <DownloadButton id='download' onClick={() => handleDownload()}>다운로드</DownloadButton>
        </SearchWrapper>

        <AnalysisChart>
          { data.map((item) => (
            <div key={item.material + item.point}>
              <p>{ item.material } - { item.point }</p>
              <Chart dataSource={item.data}>
                <Series valueField='potential' />

                <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
                  <Point visible={false} />
                </CommonSeriesSettings>

                <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
                  <Grid visible={true} />
                </ArgumentAxis>

                <ValueAxis>
                  <Label customizeText={customizeLabel} />
                </ValueAxis>

                <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

                <Tooltip enabled={true} contentRender={customizeTooltip}  />
                <Crosshair enabled={true}>
                  <HorizontalLine visible={false} />
                </Crosshair>

                <Legend visible={false} />
              </Chart>
            </div>
          ))}
        </AnalysisChart>
      </Box>
    </Content>
  );
}