import { useState, useEffect } from 'react';
// prop-types
import PropTypes from 'prop-types';
// @form
import { useController } from 'react-hook-form';
// styles
import { Wrapper, Label, Button, Message } from './styles';

// ----------------------------------------------------------------------

TextField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object
};

// ----------------------------------------------------------------------

export default function TextField({ name, control, label, $exception, $password, ...props }) {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const [ errors, setErrors ] = useState(null);

  const [ type, setType ] = useState('password');

  useEffect(() => {
    if ( !error ) return setErrors(null);
    if ( $exception && error.type !== 'required' ) return setErrors(null);
    setErrors(true);
    // eslint-disable-next-line
  }, [error]);

  return (
    <Wrapper $error={errors}>

      { label &&
        <Label>{ label }</Label>
      }

      { $password ? (
        <div>
          <input {...field} {...props} type={type} />
          <Button
            type='button'
            name={type === 'password' ? 'on' : 'off'}
            onClick={() => setType( type === 'password' ? 'text' : 'password' )}
          />
        </div>
      ) : (
        <input {...field} {...props} />
      )}

      { !$exception && error && error.type !== 'required' &&
        <Message>{ error.message }</Message>
      }

    </Wrapper>
  );
};