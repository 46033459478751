import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import TextField from '../../components/text-field';
import Button from '../../components/button';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import message from '../../utils/message';
import { REGEXP_EMAIL, REGEXP_PASSWORD  } from '../../utils/regexp';
// styles
import { FormContent, Message, Logo } from '../../styles';
// apis
import { login } from '../../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  email: yup.string()
    .required()
    .matches(REGEXP_EMAIL),
  password: yup.string()
    .required()
    .min(8)
    .max(16)
    .matches(REGEXP_PASSWORD)
});

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate  = useNavigate();

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [ alert, setAlert ] = useState(null);

  const onError = (error) => {
    const errors = Object.keys(error);
    for ( let i = 0; i < errors.length; i++ ) {
      if ( error[errors[i]].type !== 'required' ) {
        setAlert( message('LOGIN', 'default') );
        break;
      }
      setAlert(null);
    }
  };

  const onSubmit = async (data) => {
    await login(data.email, data.password)
      .then((response) => {
        const { temp, expiration, permission, admin } = response;
        if ( permission === 0 && !admin ) return Promise.reject( new Error(message('LOGIN', 'default')) );
        if ( permission === 0 && admin ) return navigate('/system/company', {state: {submit: true}});

        if ( temp || expiration ) {
          return navigate('/change-password', {state: { expiration, temp }})
        } else {
          let path = '/soil';
          if ( permission === 2 ) return path = '/potential';
          return navigate(`${ path }/dashboard`, {state: {submit: true}});
        }
      })
      .catch((error) => setAlert(error.message));
  };

  return (
    <FormContent>
      <Logo />

      <form onSubmit={ handleSubmit(onSubmit, onError) }>
        <TextField
          type='text'
          name='email'
          control={control}
          placeholder='이메일'
          $exception
        />

        <TextField
          type='password'
          name='password'
          control={control}
          placeholder='비밀번호'
          autoComplete='off'
          $exception
        />

        <Message>{ alert }</Message>

        <Button label='로그인' />
      </form>
    </FormContent>
  );
}