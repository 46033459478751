import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../store';
// components
import Breadcrumbs from '../../components/breadcrumbs';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import Chart, { Series, CommonSeriesSettings, Point, ArgumentAxis, Grid, Legend, Tooltip, Crosshair, HorizontalLine, CommonAxisSettings, ValueAxis, Label } from 'devextreme-react/chart';
// styles
import { Content, Box, SearchWrapper, Tabs, Tab, SearchButton, DownloadButton, AnalysisChart } from '../../styles';
// apis
import { getSoilSensorList, getSoilAnalysis, downloadSoilAnalysis } from '../../apis';

// ----------------------------------------------------------------------

const current = new Date();
const minDate = new Date(current);
minDate.setFullYear(2000);

// ----------------------------------------------------------------------

export default function SoilAnalysisPage() {

  const group = useSelector(state => state.group.soil);

  const [ sensors, setSensors ] = useState([]);
  const [ sensor, setSensor ] = useState('none');

  const [ tab, setTab ] = useState('monthly');

  const [ date, setDate ] = useState(current);

  const [ data, setData ] = useState([]);

  useEffect(() => {
    if ( group ) {
      ( async () =>
        await getSoilSensorList(setSensors, group.id)
      )();
    }
  }, [ group ]);

  useEffect(() => {
    if ( sensors.length > 0 ) {
      setSensor(sensors[0].id);
      ( async () =>
        await getSoilAnalysis(setData, sensors[0].id, date, tab)
      )();
    } else {
      setSensor('none');
      setData([]);
    }
    // eslint-disable-next-line
  }, [ sensors ]);

  const handleSearch = async () => await getSoilAnalysis(setData, sensor, date, tab);

  const handleDownload = async () => {
    if ( group ) {
      await downloadSoilAnalysis(sensor, date, tab);
    }
  };

  const customizeTooltip = (info, legend) => {
    return (
      <div style={{color: '#FFFFFF', fontSize: '12px', letterSpacing: '1px', textAlign: 'center', lineHeight: '160%'}}>
        { info.argument }<br />
        { info.value } {legend}
      </div>
    );
  };

  return (
    <Content>
      <Breadcrumbs
        heading='데이터 분석'
        links={[
          { name: '토양' },
          { name: '데이터 분석' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <div>
            <select value={sensor.id} onChange={(event) => setSensor(event.target.value)}>
              { sensor === 'none' ? (
                <option value={'none'}>센서 목록이 없습니다.</option>
              ) : (
                sensors.map((item) => (
                  <option key={item.id} value={item.id}>No.{ item.number }</option>
                ))
              )}
            </select>

            <Tabs>
              <Tab onClick={() => setTab('monthly')} $active={tab === 'monthly' && true}>월간</Tab>
              <Tab onClick={() => setTab('yearly')} $active={tab === 'yearly' && true}>연간</Tab>
            </Tabs>

            <DateBox
              type='date'
              value={date}
              elementAttr={{ class: 'small' }}
              onValueChanged={(event) => setDate(event.value)}
              displayFormat={ tab === 'monthly' ? 'yyyy.MM' : 'yyyy' }
              calendarOptions={{
                maxZoomLevel: tab === 'monthly' ? 'year' : 'decade',
                minZoomLevel: 'century'
              }}
              openOnFieldClick={true}
              useMaskBehavior={true}
              min={minDate}
              max={current}
              pickerType='calendar'
            />
            
            <SearchButton onClick={() => handleSearch() }>검색</SearchButton>
          </div>

          <DownloadButton id='download' onClick={() => handleDownload()}>다운로드</DownloadButton>
        </SearchWrapper>

        <AnalysisChart>
          <p>수소이온농도</p>
          <Chart dataSource={data}>
            <Series valueField='ph' />

            <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
              <Point visible={false} />
            </CommonSeriesSettings>

            <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
              <Grid visible={true} />
            </ArgumentAxis>

            <ValueAxis>
              <Label customizeText={(e) => `${e.valueText} ph`} />
            </ValueAxis>

            <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

            <Tooltip enabled={true} contentRender={(info) => customizeTooltip(info, 'ph')} />
            <Crosshair enabled={true}>
              <HorizontalLine visible={false} />
            </Crosshair>

            <Legend visible={false} />
          </Chart>

          <p>산화환원전위</p>
          <Chart dataSource={data}>
            <Series valueField='orp' />

            <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
              <Point visible={false} />
            </CommonSeriesSettings>

            <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
              <Grid visible={true} />
            </ArgumentAxis>

            <ValueAxis>
              <Label customizeText={(e) => `${e.valueText} mV`} />
            </ValueAxis>

            <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

            <Tooltip enabled={true} contentRender={(info) => customizeTooltip(info, 'mV')}  />
            <Crosshair enabled={true}>
              <HorizontalLine visible={false} />
            </Crosshair>

            <Legend visible={false} />
          </Chart>

          <p>비저항</p>
          <Chart dataSource={data}>
            <Series valueField='resistivity' />

            <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
              <Point visible={false} />
            </CommonSeriesSettings>

            <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
              <Grid visible={true} />
            </ArgumentAxis>

            <ValueAxis>
              <Label customizeText={(e) => `${e.valueText} Ω·cm`} />
            </ValueAxis>

            <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

            <Tooltip enabled={true} contentRender={(info) => customizeTooltip(info, 'Ω·cm')}  />
            <Crosshair enabled={true}>
              <HorizontalLine visible={false} />
            </Crosshair>

            <Legend visible={false} />
          </Chart>

          <p>함수율</p>
          <Chart dataSource={data}>
            <Series valueField='moisture' />

            <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
              <Point visible={false} />
            </CommonSeriesSettings>

            <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
              <Grid visible={true} />
            </ArgumentAxis>

            <ValueAxis>
              <Label customizeText={(e) => `${e.valueText} %`} />
            </ValueAxis>

            <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

            <Tooltip enabled={true} contentRender={(info) => customizeTooltip(info, '%')}  />
            <Crosshair enabled={true}>
              <HorizontalLine visible={false} />
            </Crosshair>

            <Legend visible={false} />
          </Chart>

          <p>토양 온도</p>
          <Chart dataSource={data}>
            <Series valueField='soilTemp' />

            <CommonSeriesSettings type='line' argumentField='date' hoverMode='none'>
              <Point visible={false} />
            </CommonSeriesSettings>

            <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
              <Grid visible={true} />
            </ArgumentAxis>

            <ValueAxis>
              <Label customizeText={(e) => `${e.valueText} ℃`} />
            </ValueAxis>

            <CommonAxisSettings valueMarginsEnabled={true} minValueMargin={0.03} maxValueMargin={0.03} />

            <Tooltip enabled={true} contentRender={(info) => customizeTooltip(info, '℃')}  />
            <Crosshair enabled={true}>
              <HorizontalLine visible={false} />
            </Crosshair>

            <Legend visible={false} />
          </Chart>
        </AnalysisChart>
      </Box>
    </Content>
  );
}