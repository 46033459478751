import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
// slices
import { auth, token, account, group, loading } from './slices';

// ----------------------------------------------------------------------

const config = { key: 'root', storage, whitelist: ['auth'] };

const reducer = combineReducers({
  auth: auth.reducer,
  token: token.reducer,
  account: account.reducer,
  group: group.reducer,
  loading: loading.reducer
});

const store = configureStore({
  reducer: persistReducer(config, reducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

// ----------------------------------------------------------------------

export { useSelector } from 'react-redux';
export { SET_AUTH, SET_TOKEN, DELETE_TOKEN, SET_ACCOUNT, DELETE_ACCOUNT, SET_SOIL_GROUP, SET_POTENTIAL_GROUP, DELETE_GROUP, SET_LOADING, DELETE_LOADING } from './slices';

export const persistor = persistStore(store);
export default store;