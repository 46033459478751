import { useState, useEffect } from 'react';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import DateRangeBox from 'devextreme-react/date-range-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, SearchWrapper, Message } from '../../../styles';
// apis
import { getLoginLogs } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

const msInDay = 1000 * 60 * 60 * 24;
const now = new Date();

// ----------------------------------------------------------------------

export default function LoginLogPage() {

  const [ start, setStart ] = useState(new Date(now.getTime() - msInDay * 7));
  const [ end, setEnd ] = useState(new Date(now.getTime()));

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('name');
  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  useEffect(() => {
    ( async () =>
      await getLoginLogs(setData, start, end, search(), page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value - 1);
    getLoginLogs(setData, start, end, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getLoginLogs(setData, start, end, search(), 0, Number(event.target.value));
  };

  const handleSearch = () => {
    let difference = Math.abs( start.getTime() - end.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( difference > 90 ) return;

    setPage(0);
    getLoginLogs(setData, start, end, search(), 0, size);
  };

  const search = () => {
    return {
      name : type === 'name' ? keyword : '',
      requestEmail: type === 'email' ? keyword : ''
    }
  };

  const onCurrentValueChange = ({ value: [startDate, endDate] }) => {
    setStart(startDate);
    setEnd(endDate);
  };

  return (
    <Content>
      <Breadcrumbs
        heading='로그인 기록'
        links={[
          { name: '운영 관리' },
          { name: '로그인 기록' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <div>
            <DateRangeBox defaultValue={[start, end]} onValueChanged={onCurrentValueChange} />

            <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
              <option value='number'>이름</option>
              <option value='email'>로그인 계정</option>
            </select>

            <SearchField
              value={keyword}
              onChange={setKeyword}
              handleSearch={handleSearch}
              handleInitialize={handleSearch}
              placeholder='검색어를 입력하세요.'
            />

            <Message $info>최대 90일까지 조회</Message>
          </div>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
        >
          <Column caption='일시' dataField='accessAt' alignment='center' />
          <Column caption='로그인 계정' dataField='requestEmail' alignment='center' />
          <Column caption='이름' dataField='name' alignment='center' />
          <Column caption='기업' dataField='company' alignment='center' />
          <Column caption='소속' dataField='affiliation' alignment='center' />
          <Column caption='상태' dataField='status' alignment='center' />
          <Column caption='IP' dataField='ip' alignment='center' />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

      </Box>
    </Content>
  );
}