import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deleteTestCases = async (testCaseId) => {
  return await axios.delete(`test-cases/${testCaseId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deleteTestCases;