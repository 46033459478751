import { useState, useEffect, useRef } from 'react';
// store
import { useSelector } from '../../store';
// hooks
import useResponsive from '../../hooks/useResponsive';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Panels, SliderButton, Weathers, WeatherIcon, Legend, Maps, MapSearchWrapper, ScrollBar, DataPopup } from '../../styles';
// apis
import { getWeathers, getSoilSensorList, getSoilDashboards, refreshSoilDashboards } from '../../apis';

// ----------------------------------------------------------------------

const weather = {
  '맑음': 'sun', '구름많음': 'cloud', '흐림': 'cloudy'
};

// ----------------------------------------------------------------------

export default function SoilDashboardPage() {
  const isDesktop = useResponsive('up', 'md');
  const isMobile = useResponsive('down', 'sm');

  const group = useSelector(state => state.group.soil);

  const mapRef = useRef(null);

  const [ naver, setNaver ] = useState(null);
  const [ map, setMap ] = useState(null);
  const [ markers, setMarkers ] = useState(null);

  const [ weathers, setWeathers ] = useState({ message: '일시적으로 날씨 정보가 제공되지 않습니다.' });
  const [ sensors, setSensors ] = useState([]);
  const [ data, setData ] = useState([]);

  const [ dataset, setDataset ] = useState({ total: 0, sensor: 0, battery: 0 });
  const [ visible, setVisible ] = useState(false);

  const [ row, setRow ] = useState([]);

  // STYLES
  const [ active, setActive ] = useState(isDesktop ? false: true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNaver( window.naver );
  }, []);

  useEffect(() => {
    if ( !mapRef.current || !naver || !naver?.maps ) {
      setMap(null);
    } else {
      setMap( new naver.maps.Map( mapRef.current ) );
    }
  }, [naver, naver?.maps]);

  useEffect(() => {
    if ( group ) {
      handleDataset();
      setVisible(false);

      if ( map && naver.maps !== null ) {
        const options = {
          center: new naver.maps.LatLng( group.latitude, group.longitude ),
          zoom: group.zoom
        };
        map.setOptions( options );
      }
    }
    // eslint-disable-next-line
  }, [group, map]);

  useEffect(() => {
    if ( group ) {
      ( async () =>
        await getSoilDashboards(group.id)
          .then((response) => {
            const map = new Map();
            response.forEach((item) => map.set(item.id, item));
            sensors.forEach((item) => map.set(item.id, { ...map.get(item.id), ...item }));
            const merged = Array.from(map.values());
            setData(merged);
            setRow(merged);
          })
          .catch(() => {
            const merged = sensors.map((sensor) => {
              return { ...sensor,
                acquisitionAt: null,
                status: null,
                ph: null,
                orp: null,
                resistivity: null,
                moisture: null,
                soilTemp: null,
                battery: null,
                boardTemp: null
              };
            });
            setData(merged);
            setRow(merged);
          })
      )();
    }
  }, [sensors, group]);

  useEffect(() => {
    if ( data.length > 0 ) {
      const sensor = data.filter((value) => { return value.status !== 'GOOD'; })
      const battery = data.filter((value) => { return value.battery && value.battery < 60; })
      setDataset({ total: data.length, sensor: sensor.length, battery: battery.length });
    } else {
      setDataset({ total: 0, sensor: 0, battery: 0 });
    }
  }, [data]);

  useEffect(() => {
    if ( map ) {
      let array = [];

      if ( markers ) {
        for ( let i = 0; i < markers.length; i++ ) {
          markers[i].setMap(null);
        }
      }

      for ( let i = 0; i < data.length; i++ ) {
        const icon = icons(data[i]);
        const marker = new naver.maps.Marker({
          position: new naver.maps.LatLng( data[i].latitude, data[i].longitude),
          map,
          icon
        });

        naver.maps.Event.addListener(marker, 'click', function() {
          setVisible(data[i]);
        });

        array.push(marker);
      }
      setMarkers(array);
    }
    // eslint-disable-next-line
  }, [data, map]);

  const icons = (sensor) => {
    let color = '';
    if ( sensor.status !== 'GOOD' ) {
      color = 'red';
    } else if ( sensor.battery && sensor.battery < 60 ) {
      color = 'yellow';
    } else {
      color = 'blue';
    }
    
    const icon = {
      content:
        `<div class="marker">` +
          `<div style="background-image: url('/assets/markers/${color}.svg');"></div>` +
          `<span>센서 ${sensor.number}</span>` +
        `</div>`,
      origin: new naver.maps.Point(0, 0),
      anchor: new naver.maps.Point(15, 39)
    };
    return icon;
  };

  const handleDataset = async () => {
    await getWeathers(group.weatherId)
      .then((response) => {
        const date = new Date();
        const updateTime = new Date(response.forecastAt);
        let difference = Math.abs( updateTime.getTime() - date.getTime() );
        difference = Math.ceil( difference / (1000 * 60 * 60) );
        if ( difference > 6 ) return setWeathers({ message: '일시적으로 날씨 정보가 제공되지 않습니다.' });

        setWeathers({
          ...response,
          icon: response.precipitationType === '없음' ? weather[response.sky] : 'rain',
          state: response.precipitationType === '없음' ? response.sky : response.precipitationType
        });
      })
      .catch(() => {
        setWeathers({ message: '일시적으로 날씨 정보가 제공되지 않습니다.' });
      });

    await getSoilSensorList(setSensors, group.id);
  };

  const handleRow = (type) => {
    if ( type === 'total' ) {
      setRow(data);
    } else if ( type === 'sensor' ) {
      const sensor = data.filter((value) => { return value.status !== 'GOOD'; })
      setRow(sensor);
    } else {
      const battery = data.filter((value) => { return value.battery && value.battery < 60; })
      setRow(battery);
    }
  };

  const handlePosition = () => {
    let latitude = group.latitude;
    let longitude = group.longitude;
    let zoom = group.zoom;
    onPositions(latitude, longitude, zoom);
    setVisible(false);
  };

  const handleErrorPosition = (value) => {
    const latitude = value.latitude;
    const longitude = value.longitude;
    onPositions(latitude, longitude, 20);

    if ( !isDesktop ) setActive(true);
  };

  const onPositions = (latitude, longitude, zoom) => {
    if ( map ) {
      const options = {
        center: new naver.maps.LatLng( latitude, longitude ),
        zoom
      };
      map.setOptions( options );
    }
  };

  const handleRefresh = () => {
    if ( group && sensors.length > 0 ) {
      ( async () =>
        await refreshSoilDashboards(group.id)
          .then((response) => {
            const map = new Map();
            response.forEach((item) => map.set(item.id, item));
            sensors.forEach((item) => map.set(item.id, { ...map.get(item.id), ...item }));
            const merged = Array.from(map.values());
            setData(merged);
            setRow(merged);
          })
          .catch(() => {
            const merged = sensors.map((sensor) => {
              return { ...sensor,
                acquisitionAt: null,
                status: null,
                battery: null,
                boardTemp: null
              };
            });
            setData(merged);
            setRow(merged);
          })
      )();
    }
  };

  return (
    <Container $active={active}>
      <Panels>
        <ScrollBar>
          <Weathers>
            { weathers.message ? (
              <p>{ weathers.message }</p>
            ) : (
              <div>
                <WeatherIcon $icon={weathers.icon}>
                  <div />
                  <div>
                    <p>{ weathers.temperature }˚</p>
                    <p>{ weathers.state }</p>
                  </div>
                </WeatherIcon>

                <div>
                  <span>강수</span>
                  <p>{ weathers.precipitation }</p>
                  <i>mm</i>

                  <span>습도</span>
                  <p>{ weathers.humidity }</p>
                  <i>%</i>

                  <span>{ weathers.windDirection }</span>
                  <p>{ weathers.windSpeed }</p>
                  <i>m/s</i>
                </div>
              </div>
            )}
          </Weathers>

          <div>
            <p>전체 현황<i title='전체: 전체 센서 수량&#10;센서 오류: 센서 응답 없음&#10;배터리 경고: 배터리 잔량 60% 미만' /></p>
            <div>
              <div onClick={() => handleRow('total')}>
                전체<br />
                <span>{ dataset.total }</span>
              </div>
              <div onClick={() => handleRow('sensor')}>
                센서 오류<br />
                <span>{ dataset.sensor }</span>
              </div>
              <div onClick={() => handleRow('battery')}>
                배터리 경고<br />
                <span>{ dataset.battery }</span>
              </div>
            </div>
          </div>

          <div>
            <p>개별 현황<i title='센서 번호 클릭 시&#10;센서가 설치된 위치로 지도가 이동하고,&#10;상세 정보를 표시합니다.' /></p>
            <DataGrid
              dataSource={row}
              noDataText=''
              columnAutoWidth={true}
              sorting={{ mode: 'none' }}
              dataRowRender={({data}) => <TableRow row={data} setVisible={setVisible} handleErrorPosition={handleErrorPosition} isMobile={isMobile} />}
            >
              <Column caption='센서' dataField='accessAt' alignment='center' />
              <Column caption='상태' dataField='name' alignment='center' />
              <Column caption='배터리' dataField='requestEmail' alignment='center' />
            </DataGrid>
          </div>

          <SliderButton onClick={() => setActive(!active)}><div /></SliderButton>
        </ScrollBar>
      </Panels>

      <Maps ref={mapRef}>
        <MapSearchWrapper>
          <button onClick={() => handlePosition()} disabled={ group ? false : true }>지도 초기화</button>
          <button onClick={() => handleRefresh()} disabled={ group ? false : true }>데이터 새로고침</button>
        </MapSearchWrapper>

        <Legend>
          <p>범례</p>
          <div /><p>정상</p>
          <div /><p>센서 오류</p>
          <div /><p>배터리 경고</p>
        </Legend>
      </Maps>
      
      { visible &&
        <DataPopup>
          <button onClick={() => setVisible(false)} />

          <p>센서 {visible.number}</p>

          <table>
            <tbody>
              <tr>
                <td><div>수소이온농도</div></td>
                <td>{visible.ph ? `${visible.ph} ph` : '-'}</td>
              </tr>
              <tr>
                <td><div>산화환원전위</div></td>
                <td>{visible.orp ? `${visible.orp} mV` : '-'}</td>
              </tr>
              <tr>
                <td><div>비저항</div></td>
                <td>{visible.resistivity ? `${visible.resistivity} Ω·cm` : '-'}</td>
              </tr>
              <tr>
                <td><div>함수율</div></td>
                <td>{visible.moisture ? `${visible.moisture} %` : '-'}</td>
              </tr>
              <tr>
                <td><div>토양 온도</div></td>
                <td>{visible.soilTemp ? `${visible.soilTemp} ℃` : '-'}</td>
              </tr>
              <tr>
                <td><div>장비 온도</div></td>
                <td>{visible.boardTemp ? `${visible.boardTemp} ℃` : '-'}</td>
              </tr>
              <tr>
                <td><div>배터리 잔량</div></td>
                <td style={{ color: visible.battery ? visible.battery < 60 ? '#FFAB00' : '' : '' }}>{visible.battery ? `${visible.battery} %` : '-'}</td>
              </tr>
              <tr>
                <td><div>장비 상태</div></td>
                <td style={{ color: visible.status ? visible.status === 'GOOD' ? '' : '#FF5630' : '' }}>{visible.status ? visible.status === 'GOOD' ? '정상' : '오류' : '-'}</td>
              </tr>
              <tr>
                <td><div>업데이트 시간</div></td>
                <td>{ visible.acquisitionAt }</td>
              </tr>
            </tbody>
          </table>
        </DataPopup>
      }
    </Container>
  );
}

function TableRow({ row, setVisible, handleErrorPosition, isMobile}){

  const battery = row.battery ? row.battery < 60 ? '#FFAB00' : '' : '';
  const status = row.status ? row.status === 'GOOD' ? '' : '#FF5630' : '';

  return (
    <tr style={{ cursor: 'pointer' }} onClick={() => {
      if ( !isMobile ) setVisible(row);
      handleErrorPosition({ latitude: row.latitude, longitude: row.longitude });
    }}>
      <td>{ row.number }</td>
      <td style={{ color: status }}>{ row.status ? row.status === 'GOOD' ? '정상' : '오류' : '-' }</td>
      <td style={{ color: battery }}>{ row.battery ? `${row.battery} %` : '-' }</td>
    </tr>
  );
}