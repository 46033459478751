import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateTestCases = async (testCaseId, data) => {
  return await axios.put(`test-cases/${testCaseId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateTestCases;