import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getTestCasesList = async (setData, groupId) => {
  return await axios.get(`groups/potential-groups/${groupId}/test-cases`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getTestCasesList;