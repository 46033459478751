import axios from '../_utils/axios';
import message from '../../utils/message';

// ----------------------------------------------------------------------

const changePassword = async (currentPassword, newPassword, temp) => {
  const result = temp ? {tempPassword: currentPassword, newPassword} : {currentPassword: currentPassword, newPassword};

  return await axios.put(`accounts/${temp ? 'temp-' : ''}password`, result)
    .catch(() => {
      return Promise.reject(
        new Error(
          message('PASSWORD', 'default')
        )
      );
    });
};

export default changePassword;