import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  margin: 30px 0 25px;

  ul {
    display: flex;
    margin-bottom: 10px;

    li {
      display: flex;
      align-items: center;
    }

    li:last-child {
      color: #637381;
    }

    li::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-image: url(/assets/breadcrumbs/arrow.png);
      background-size: 6px 8px;
      background-position: center center;
      background-repeat: no-repeat;
      margin: 0 2px;
    }
    li:last-child::after {
      display: none
    }
  }

  > p {
    font-size: 24px;
    font-weight: 500;
  }
`;