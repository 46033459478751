import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import TableRow from './TableRow';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, SearchWrapper, AddButton, IssuancePassword } from '../../../styles';
// apis
import { getMembers, deleteMembers, blockMembers, unblockMembers, unlockMembers, getPassword } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

// ----------------------------------------------------------------------

export default function MemberPage() {
  const navigate  = useNavigate();

  const account = useSelector(state => state.account);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  const [ message, setMessage ] = useState(null);
  
  const [ dialog, setDialog ] = useState({
    confirm: { visible: false },
    password: { visible: false }
  });

  useEffect(() => {
    ( async () =>
      await getMembers(setData, keyword, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value - 1);
    getMembers(setData, keyword, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getMembers(setData, keyword, 0, Number(event.target.value));
  };

  const handleSearch = () => {
    setPage(0);
    getMembers(setData, keyword, 0, size);
  };

  const handleAction = async () => {
    await actions()
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          confirm: { visible: false }
        });
      })
      .catch(() => {
        setMessage(`사용자를 ${ dialog.confirm.title }할 수 없습니다. 나중에 다시 시도해 주세요.`);
      });
  };

  const actions = async () => {
    const id = dialog.confirm.id;
    // eslint-disable-next-line
    switch ( dialog.confirm.type ) {
      case 'delete':
        return await deleteMembers(id);
      case 'block':
        return await blockMembers(id);
      case 'unblock':
        return await unblockMembers(id);
      case 'unlock':
        return await unlockMembers(id);
    }
  };

  const handlePassword = async (id) => {
    const result = { ...dialog,
      password: { visible: true }
    };
    
    await getPassword(id)
      .then((response) => {
        result.password.answer = response;
        result.password.message = null;
      })
      .catch(() => {
        result.password.answer = null;
        result.password.message = '임시 비밀번호 발급을 할 수 없습니다. 나중에 다시 시도해 주세요.';
      });

    setDialog(result);
  };

  const handleReset = () => {
    if ( dialog.confirm.type !== 'delete' ) return getMembers(setData, keyword, page, size);
    handleSearch();
  };

  return (
    <Content>
      <Breadcrumbs
        heading='사용자 관리'
        links={[
          { name: '시스템 관리' },
          { name: '사용자 관리' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            handleSearch={handleSearch}
            handleInitialize={handleSearch}
            placeholder='이름을 입력하세요.'
          />
          <AddButton onClick={() => navigate('create')}>등록</AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          dataRowRender={({data}) => <TableRow row={data} account={account} dialog={dialog} setDialog={setDialog} handlePassword={handlePassword} />}
        >
          <Column caption='이름' dataField='name' alignment='center' />
          <Column caption='이메일' dataField='email' alignment='center' />
          <Column caption='기업' dataField='company' alignment='center' />
          <Column caption='소속' dataField='affiliation' alignment='center' />
          <Column caption='연락처' dataField='phone' alignment='center' />
          <Column caption='권한' dataField='role' alignment='center' />
          <Column caption='계정 상태' dataField='blocked' alignment='center' />
          <Column caption='로그인 차단' dataField='locked' alignment='center' />
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

        { dialog.confirm.visible &&
          <Dialog
            title={ `사용자 ${dialog.confirm.title}` }
            icon={ dialog.confirm.type === 'delete' && dialog.confirm.type }
            message={message}
            actions={
              <div>
                <button onClick={() => handleAction()}>
                  { dialog.confirm.title }
                </button>
                <button onClick={() => {
                  setDialog({ ...dialog,
                    confirm: { visible: false }
                  });
                  setMessage(null);
                }}>
                  취소
                </button>
              </div>
            }
          >
            <p>선택하신 사용자를 { dialog.confirm.title }하시겠습니까?</p>
          </Dialog>
        }

        { dialog.password.visible &&
          <Dialog
            title='임시 비밀번호 발급'
            icon='password'
            message={dialog.password.message && dialog.password.message}
            actions={
              <button onClick={() => {
                setDialog({ ...dialog,
                  password: { visible: false }
                });
              }}>
                확인
              </button>
            }
          >
            { dialog.password.answer &&
              <IssuancePassword>
                <p>임시 비밀번호를 사용할 수 있습니다.</p>
                <div>{ dialog.password.answer }</div>
                <p>
                  이 번만 임시 비밀번호를 확인할 수 있습니다.<br />
                  하지만 언제든지 새 임시 비밀번호를 생성할 수 있습니다.
                </p>
              </IssuancePassword>
            }
          </Dialog>
        }
        
      </Box>
    </Content>
  );
}