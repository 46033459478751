import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const createTestCases = async (data) => {
  return await axios.post('test-cases', data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createTestCases;