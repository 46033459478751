import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const disableSoilSensors = async (sensorId) => {
  return await axios.delete(`soil-sensors/${sensorId}/enable`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default disableSoilSensors;