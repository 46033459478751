import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import TextField from '../../../components/text-field';
import Button from '../../../components/button';
import { Dialog } from '../../../components/dialog';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// utils
import { REGEXP_EMAIL, REGEXP_LETTER, REGEXP_SPECIAL, REGEXP_PHONE, REGEXP_PASSWORD } from '../../../utils/regexp';
// styles
import { Content, FormContent, Box, Label, WithButton, Select, Message, ButtonWrapper } from '../../../styles';
// apis
import { checkEmail, searchCompanies, createMembers, updateMembers } from '../../../apis';

// ----------------------------------------------------------------------

const roles = { '사용자': 'MEMBER', '관리자': 'ADMIN' };

const schema = {
  create: yup.object().shape({
    email: yup.string()
      .required()
      .matches(REGEXP_EMAIL, '이메일 형식을 확인해 주세요.')
      .max(30, '30자 이내로 입력해주세요.'),
    name: yup.string()
      .required()
      .matches(REGEXP_LETTER, '한글·영문·숫자를 사용해 주세요.')
      .max(20, '20자 이내로 입력해주세요.'),
    affiliation: yup.string()
      .required()
      .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
      .max(30, '30자 이내로 입력해주세요.'),
    phone: yup.string()
      .trim()
      .matches(REGEXP_PHONE, {message: '연락처 형식을 확인해 주세요.', excludeEmptyString: true}),
    password: yup.string()
      .required()
      .min(8, '8 - 16자 이내로 입력해주세요.')
      .max(16, '8 - 16자 이내로 입력해주세요.')
      .matches(REGEXP_PASSWORD, '한글·영문·숫자·특수 문자~!@#$%^&*()+|= 를 사용해 주세요.'),
    confirm: yup.string()
      .required('비밀번호가 일치하지 않습니다.')
      .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  }),
  update: yup.object().shape({
    name: yup.string()
      .required()
      .matches(REGEXP_LETTER, '한글·영문·숫자를 사용해 주세요.')
      .max(20, '20자 이내로 입력해주세요.'),
    affiliation: yup.string()
      .required()
      .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
      .max(30, '30자 이내로 입력해주세요.'),
    phone: yup.string()
      .trim()
      .matches(REGEXP_PHONE, {message: '연락처 형식을 확인해 주세요.', excludeEmptyString: true}),
  })
};

// ----------------------------------------------------------------------

export default function MemberFormPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const pathname = useLocation().pathname.split('/')[3];
  const create = pathname === 'create' ? true : false;
  const title = create ? '등록' : '수정';

  const {
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema[pathname]),
    defaultValues: {
      email: state?.email || '',
      name: state?.name || '',
      affiliation: state?.affiliation || '',
      phone: state?.phone || '',
      password: '',
      confirm: ''
    }
  });
  
  const [ visible, setVisible ] = useState(false);

  const [ companies, setCompanies ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = companies;
  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ select, setSelect ] = useState({ id: null, name: null });
  const [ company, setCompany ] = useState({ id: null, name: '' });
  const [ required, setRequired ] = useState(false);

  const [ role, setRole ] = useState( state? roles[state.role] : 'MEMBER' );

  const [ checked, setChecked ] = useState({ result: null, email: null });
  const [ message, setMessage ] = useState({ checked: null, companies: null, error: null });

  useEffect(() => {
    if ( !create && !state ) return navigate(-1);

    ( async () =>
      await searchCompanies(setCompanies, keyword, page)
        .then((response) => {
          if ( state ) {
            const result = response.filter((item) => item.name === state.company )[0];
            if ( result ) {
              delete result.createdAt;
              setSelect(result);
              setCompany(result);
            }
          }
        })
    )();
    // eslint-disable-next-line
  }, [ create ]);

  const handleSearch = () => {
    setPage(0);
    searchCompanies(setCompanies, keyword, 0);
  };

  const handlePage = (value) => {
    setPage(value - 1);
    searchCompanies(setCompanies, keyword, value-1);
  };

  const handleCompanies = () => {
    if ( !select.id ) return setMessage({ ...message, companies: '기업을 선택해주세요.' });
    setCompany(select);
    setVisible(false);
    setRequired(false);
    setMessage({ ...message, companies: null });
  };

  const handleCancel = () => {
    setVisible(false);
    if ( !select.id ) return setMessage({ ...message, companies: null });
    if ( !company.id ) return setSelect({ id: null, name: null });
    if ( company.id !== select.id ) return setSelect(company);
  };

  const handleChecked = async () => {
    const value = getValues('email');

    if ( value.length === 0 || (value.length > 0 && value.replace(/ /g, '').length === 0) ) {
      setError('email', {type: 'required'});
      setMessage({ ...message, checked: null });
    } else if ( !REGEXP_EMAIL.test(value) ) {
      setError('email', {type: 'matches', message: '이메일 형식을 확인해 주세요.'});
      setMessage({ ...message, checked: null });
    } else if ( value.length > 30 ) {
      setError('email', {type: 'max', message: '30자 이내로 입력해주세요.'});
      setMessage({ ...message, checked: null });
    } else {
      clearErrors('email');
      await checkEmail(value)
        .then((response) => {
          setChecked({ result: !response, email: value });
          setMessage({ ...message, checked: !response ? '사용하실 수 있는 이메일입니다.' : '이미 등록된 이메일입니다.' });
        })
        .catch((error) => {
          setMessage({ ...message, checked: error.message });
        });
    }
  };

  const onError = () => {
    if ( !company.id ) return setRequired(true);
  };

  const onSubmit = async (data) => {
    if ( !company.id ) return setRequired(true);

    if ( create ) {
      if ( checked.result === null || checked.email !== data.email ) {
        setChecked({ result: null, email: null });
        setMessage({ ...message, checked: '중복 체크 후 진행하실 수 있습니다.' });
      } else if ( checked.result ) {
        const result = { email: data.email, name: data.name, role: role, companyId: company.id, affiliation: data.affiliation, phone: data.phone, password: data.password };
        if ( data.phone.length === 0 ) delete result.phone;
        await createMembers(result)
          .then(() => {
            navigate('/system/member', { state: {submit: true} });
          })
          .catch(() => {
            setMessage({ ...message, error: '사용자를 등록할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      }
    } else {
      const result = { name: data.name, role: role, companyId: company.id, affiliation: data.affiliation, phone: data.phone };
      if ( data.phone.length === 0 ) delete result.phone;
      await updateMembers(state.id, result)
        .then(() => {
          navigate('/system/member', { state: {submit: true} });
        })
        .catch(() => {
          setMessage({ ...message, error: '사용자를 수정할 수 없습니다. 나중에 다시 시도해 주세요.' });
        });
    }
  };

  return (
    <Content>
      <Breadcrumbs
        heading={`사용자 ${title}`}
        links={[
          { name: '시스템 관리' },
          { name: '사용자 관리', link: '/system/member' },
          { name: `사용자 ${title}` },
        ]}
      />

      <FormContent>
        <Box>
          <form onSubmit={ handleSubmit(onSubmit, onError) }>

            <WithButton>
              <Label>이메일</Label>
              <div>
                <TextField
                  type='text'
                  name='email'
                  control={control}
                  disabled={!create && true}
                />
                <button type='button' onClick={() => handleChecked()}>중복 체크</button>
              </div>
            </WithButton>
            { message.checked &&
              <Message $success={ checked.result }>{ message.checked }</Message>
            }

            <TextField
              type='text'
              label='이름'
              name='name'
              control={control}
            />

            <div>
              <Label>권한</Label>
              <Select value={role} onChange={(event) => setRole(event.target.value)}>
                <option value='MEMBER'>사용자</option>
                <option value='ADMIN'>관리자</option>
              </Select>
            </div>

            <WithButton $error={required}>
              <Label>기업</Label>
              <div>
                <input type='text' value={company.name} disabled />
                <button type='button' onClick={() => setVisible(true)}>검색</button>
              </div>
            </WithButton>

            <TextField
              type='text'
              label='소속'
              name='affiliation'
              control={control}
            />

            <TextField
              type='text'
              label='연락처'
              name='phone'
              control={control}
              placeholder='000-0000-0000'
            />

            <TextField
              type='text'
              label='비밀번호'
              name='password'
              control={control}
              autoComplete='off'
              disabled={!create && true}
              $password
            />

            <TextField
              name='confirm'
              control={control}
              label='비밀번호 확인'
              autoComplete='off'
              disabled={!create && true}
              $password
            />

            { message.error &&
              <Message>{ message.error }</Message>
            }

            <ButtonWrapper>
              <Button label={title} disabled={Object.keys(errors).length > 0 ? true : false} />
              <button type='button' onClick={() => navigate(-1)}>취소</button>
            </ButtonWrapper>
          </form>
        </Box>
      </FormContent>

      { visible &&
        <Dialog
          message={message.companies}
          actions={
            <div>
              <button onClick={() => handleCompanies()} disabled={select.id ? false : true}>
                적용
              </button>
              <button onClick={() => handleCancel()}>
                취소
              </button>
            </div>
          }
        >
          <SearchField
            value={keyword}
            onChange={setKeyword}
            handleSearch={handleSearch}
            handleInitialize={handleSearch}
            placeholder='기업명을 입력하세요.'
          />

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            dataRowRender={({data}) => <TableRow row={data} select={select} setSelect={setSelect} />}
          >
            <Column />
            <Column caption='기업명' dataField='name' alignment='center' />
          </DataGrid>

          <Pagination page={page} pages={pages} handlePage={handlePage} />

        </Dialog>
      }
    </Content>
  );
}

function TableRow({ row, select, setSelect }) {
  return (
    <tr onClick={() => setSelect({ id: row.id, name: row.name })}>
      <td>
        <input type='radio' name='company' checked={ select.id && select.id === row.id ? true : false } readOnly />
      </td>
      <td>{ row.name }</td>
    </tr>
  );
}