import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, dialog, setDialog }){

  const [ visible, setVisible ] = useState(false);
  
  return (
    <>
      <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.id}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >
        <button
          onClick={() => {
            setDialog({ ...dialog,
              delete: {
                visible: true,
                id: row.id
              }
            });
            setVisible(false);
          }}
        >
          삭제
        </button>
      </Popover>
    </>
  );
}