import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { TableMoreButton } from '../../../styles';

// ----------------------------------------------------------------------

export default function TableRow({ row, account, dialog, setDialog, handlePassword }){
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);

  const disabled = account.email === row.email ? true : false;
  
  return (
    <tr>
      <td>{ row.name }</td>
      <td>{ row.email }</td>
      <td>{ row.company }</td>
      <td>{ row.affiliation }</td>
      <td>{ row.phone }</td>
      <td>{ row.role }</td>
      <td>{ row.blocked ? '비활성화' : '활성화' }</td>
      <td>{ row.locked ? '차단' : '미차단' }</td>
      <td>{ row.createdAt }</td>
      <td>
        <TableMoreButton id={`more${row.id}`} onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button
            disabled={disabled}
            onClick={() => navigate('update', { state: row })}
          >
            수정
          </button>

          <button
            disabled={disabled}
            onClick={() => {
              setDialog({ ...dialog,
                confirm: {
                  visible: true,
                  id: row.id,
                  title: '삭제',
                  type: 'delete'
                }
              });
              setVisible(false);
            }}
          >
            삭제
          </button>

          <button
            disabled={disabled}
            onClick={() => {
              setDialog({ ...dialog,
                confirm: {
                  visible: true,
                  id: row.id,
                  title: row.blocked ? '활성화' : '비활성화',
                  type: row.blocked ? 'unblock' : 'block'
                }
              });
              setVisible(false);
            }}
          >
            계정 {row.blocked ? '활성화' : '비활성화'}
          </button>

          { row.locked &&
            <button
              disabled={disabled}
              onClick={() => {
                setDialog({ ...dialog,
                  confirm: {
                    visible: true,
                    id: row.id,
                    title: '차단 해제',
                    type: 'unlock'
                  }
                });
                setVisible(false);
              }}
            >
              차단 해제
            </button>
          }

          <button
            disabled={disabled}
            onClick={() => {
              handlePassword(row.id);
              setVisible(false);
            }}
          >
            임시 비밀번호 발급
          </button>
        </Popover>
      </td>
    </tr>
  );
}