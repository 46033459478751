import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const searchChannels = async (setData, groupId, page, size) => {
  return await axios.get(`specimens/groups/${groupId}/search-page?page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
      return response.data.data.content;
    })
    .catch(() => {});
};

export default searchChannels;