import axios from '../../_utils/axios';
import message from '../../../utils/message';

// ----------------------------------------------------------------------

const checkTestCasesNumber = async (groupId, number) => {
  return await axios.get(`test-cases/groups/${groupId}/numbers/${number}/exists`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch(() => {
      return Promise.reject(
        new Error(
          message('COMMON', 'default')
        )
      );
    });
};

export default checkTestCasesNumber;