import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateSoilSerialNumber = async (sensorId, serialNumber) => {
  return await axios.put(`soil-sensors/${sensorId}/serial-number`, {serialNumber})
    .catch((error) => {
      let message = '일련 번호를 수정할 수 없습니다. 나중에 다시 시도해 주세요.';
      if ( error.response.data.code === 'ERR-305' ) message = '이미 등록된 일련 번호입니다.'
      return Promise.reject(
        new Error(message)
      );
    });
};

export default updateSoilSerialNumber;