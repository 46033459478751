import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_SPECIAL } from '../../../utils/regexp';
// apis
import { checkTestCasesNumber, createTestCases, updateTestCases } from '../../../apis';
// styles
import { Label, WithButton, FieldWrapper, Message } from '../../../styles';

// ----------------------------------------------------------------------

  const schema = yup.object().shape({
    number: yup.number()
      .typeError('숫자만 입력해 주세요.')
      .min(1, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.')
      .max(9223372036854775807, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.'),
    name: yup.string()
      .required()
      .matches(REGEXP_SPECIAL, '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.')
      .min(2, '2 - 30자 이내로 입력해주세요.')
      .max(30, '2 - 30자 이내로 입력해주세요.')
  });

// ----------------------------------------------------------------------

export default function Form({ dialog, setDialog, handleReset }) {

  const create = dialog.form.type  === 'create' ? true : false;
  const title = create ? '등록' : '수정';

  const id = dialog.form.id;
  const state = dialog.form.state;

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: state?.number || 1,
      name: state?.name || ''
    }
  });

  const [ auto, setAuto ] = useState(create);
  const [ checked, setChecked ] = useState({ result: null, number: null });
  const [ message, setMessage ] = useState({ checked: null, error: null });

  const handleChecked = async () => {
    const value = getValues('number');

    await checkTestCasesNumber(id, value)
      .then((response) => {
        setChecked({ result: !response, number: value });
        setMessage({ ...message, checked: !response ? '사용하실 수 있는 장비 번호입니다.' : '이미 등록된 장비 번호입니다.' });
      })
      .catch((error) => {
        setMessage({ ...message, checked: error.message });
      });
  };

  const onSubmit = async (data) => {
    if ( !auto
      && ( Number(checked.number) !== data.number || checked.result === null )
      && ( create || (!create && Number(state.number) !== data.number) ) 
    ) {
      setChecked({ result: null, number: null });
      setMessage({ ...message, checked: '중복 체크 후 진행하실 수 있습니다.' });
    } else if ( checked.result || (!create && Number(state.number) === data.number) || ( create && auto ) ) {
      const result = { number: data.number, autoNumber: auto, name: data.name };
      if ( create ) {
        await createTestCases({ ...result, groupId: id})
          .then(() => {
            handleReset();
            setDialog({ ...dialog,
              form: { visible: false }
            });
          })
          .catch(() => {
            setMessage({ ...message, error: '테스트 케이스를 등록할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      } else {
        delete result.autoNumber;
        await updateTestCases(state.id, result)
          .then(() => {
            handleReset();
            setDialog({ ...dialog,
              form: { visible: false }
            });
          })
          .catch(() => {
            setMessage({ ...message, error: '테스트 케이스를 수정할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      }
    }
  };

  return (
    <Dialog
      title={`테스트 케이스 ${title}`}
      message={message.error}
      actions={
        <div>
          <button onClick={() => handleSubmit((data) => onSubmit(data))()} disabled={Object.keys(errors).length > 0 ? true : false}>
            { title }
          </button>
          <button onClick={() => {
            setDialog({ ...dialog,
              form: { visible: false }
            });
          }}>
            취소
          </button>
        </div>
      }
    >
      <form onSubmit={ handleSubmit(onSubmit) }>

        <WithButton>
          <Label>테스트케이스 번호</Label>
          <div>
            <TextField
              type='number'
              name='number'
              control={control}
              disabled={auto}
            />
            <button type='button' onClick={() => handleChecked()} disabled={auto}>중복 확인</button>
          </div>
        </WithButton>

        <FieldWrapper $flex>
          <Message $success={ checked.result }>{ message.checked }</Message>

          { create &&
            <div>
              <input type='checkbox' id='checkbox' checked={auto} onChange={(event) => setAuto(event.target.checked)} />
              <label htmlFor='checkbox'>
                <span>AUTO</span>
              </label>
            </div>
          }
        </FieldWrapper>

        <TextField
          type='text'
          label='테스트 케이스'
          name='name'
          control={control}
        />

      </form>
    </Dialog>
  );
}