import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getLoginLogs = async (setData, startDate, endDate, { name, requestEmail }, page, size) => {
  
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const end = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  return await axios.get(`logs/login/search-page?start=${start} 00:00&end=${end} 23:59&name=${encodeURIComponent(name)}&requestEmail=${requestEmail}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getLoginLogs;