import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const updateSpecimens = async (channelId, data) => {
  return await axios.put(`specimens/${channelId}`, data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateSpecimens;