// sections
import PasswordForm from './PasswordForm';
// styles
import { FormContent, AccountTitle } from '../../styles';

// ----------------------------------------------------------------------

export default function AccountPage() {
  return (
    <FormContent>
      <AccountTitle>
        <p>비밀번호 변경</p>
      </AccountTitle>

      <PasswordForm />
    </FormContent>
  );
}