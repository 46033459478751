import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Container = styled.div({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1001,
  backgroundColor: 'rgba(255, 255, 255, .8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});