import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getGroups = async (name, page, pathname) => {
  let request = 'soil';
  if ( pathname === 'potential') request = 'potential';

  return await axios.get(`groups/${request}-groups/search-slice?name=${encodeURIComponent(name)}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getGroups;