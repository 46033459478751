import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  width: 235px;
  display: flex;
  
  div {
    display: flex;
    align-items: center;
    width: calc(100% - 38px);
    height: 38px;
    border: 1px solid ${(props) => props.theme.colors.border };
    border-radius: 10px 0 0 10px;
    border-right: none;

    input {
      width: ${(props) => props.$value ? 'calc(100% - 25px)' : '100%' };
      height: 100%;
      padding: 0 5px 0 10px;
      border: none;
      background-color: transparent;
    }
  }

  ${(props) => props.$width && css`
    width: ${(props) => props.$width + 'px'};
    margin: 8px 8px 10px;
  
    div {
      width: calc(100% - 32px);
      height: 32px;
    }

    ${SearchButton} {
      width: 32px;
      height: 32px;
      background-size: 20px 20px;

      &:hover {
        background-color: ${(props) => props.theme.colors.primary.dark };
      }
    }

    ${DeleteButton} {
      width: 18px;
      height: 18px;
    }
  `}
`;

export const SearchButton = styled.button`
  width: 38px;
  height: 38px;
  background-color: ${(props) => props.theme.colors.primary.main };
  border-radius: 0 10px 10px 0;
  background-image: url(/assets/inputs/search.svg);
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark };
  }
`;

export const DeleteButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: #D9D9D9;
  border-radius: 50%;
  background-image: url(/assets/inputs/delete.svg);
  background-size: 8px 8px;
  background-position: center center;
  background-repeat: no-repeat;
`;