import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getTestCases = async (setData, groupId, name, page, size) => {
  return await axios.get(`test-cases/groups/${groupId}/search-page?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getTestCases;