import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const createSoilSensors = async (data) => {
  return await axios.post('soil-sensors', data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createSoilSensors;