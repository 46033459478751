import { useState, useEffect } from 'react';
// sections
import TableRow from './TableRow';
import CompanyForm from './CompanyForm';
import PicForm from './PicForm';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, SearchWrapper, AddButton } from '../../../styles';
// apis
import { getCompanies, deleteCompanies } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

// ----------------------------------------------------------------------

export default function CompanyPage() {

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);
  
  const [ dialog, setDialog ] = useState({
    form: { visible: false },
    delete: { visible: false },
    pic: { visible: false }
  });

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    ( async () =>
      await handleReset()
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value - 1);
    getCompanies(setData, keyword, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getCompanies(setData, keyword, 0, Number(event.target.value));
  };

  const handleSearch = () => {
    setPage(0);
    getCompanies(setData, keyword, 0, size);
  };

  const handleDelete = async () => {
    await deleteCompanies(dialog.delete.id)
      .then(() => {
        handleSearch();
        setDialog({ ...dialog,
          delete: { visible: false }
        });
      })
      .catch(() => {
        setMessage('사용자를 삭제할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  const handleReset = () => getCompanies(setData, keyword, page, size);

  const cellRender = ({data}) => {
    return <TableRow row={data} dialog={dialog} setDialog={setDialog} />;
  };

  return (
    <Content>
      <Breadcrumbs
        heading='기업 관리'
        links={[
          { name: '시스템 관리' },
          { name: '기업 관리' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            handleSearch={handleSearch}
            handleInitialize={handleSearch}
            placeholder='기업명을 입력하세요.'
          />
          <AddButton onClick={() => {
            setDialog({ ...dialog,
              form: {
                visible: true,
                title: '등록',
                type: 'create'
              }
            });
          }}>
            등록
          </AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
        >
          <Column caption='기업' dataField='name' alignment='center' />
          <Column caption='담당자' alignment='center'>
            <Column caption='이름' dataField='picName' alignment='center' />
            <Column caption='소속' dataField='picAffiliation' alignment='center' />
            <Column caption='연락처' dataField='picPhone' alignment='center' />
          </Column>
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column caption='' dataField='id' cellRender={cellRender} alignment='center' />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

        { dialog.form.visible &&
          <CompanyForm dialog={dialog} setDialog={setDialog} handleReset={handleReset} />
        }

        { dialog.delete.visible &&
          <Dialog
            title='기업 삭제'
            icon='delete'
            message={ message }
            actions={
              <div>
                <button onClick={() => handleDelete()}>
                  삭제
                </button>
                <button onClick={() => {
                  setDialog({ ...dialog,
                    delete: { visible: false }
                  });
                  setMessage(null);
                }}>
                  취소
                </button>
              </div>
            }
          >
            <span>
              선택하신 기업에 소속된<br />
              모든 하위 사용자도 삭제됩니다.
            </span>
            <p>삭제하시겠습니까?</p>
          </Dialog>
        }

        { dialog.pic.visible &&
          <PicForm dialog={dialog} setDialog={setDialog} handleReset={handleReset} />
        }
        
      </Box>
    </Content>
  );
}