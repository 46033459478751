const MESSAGE = {
  LOGIN: {
    'ERR-022': '해당 계정은 비활성화 상태입니다. 관리자에게 문의해 주세요.',
    'ERR-023': '이메일 또는 비밀번호를 5회 이상 잘못 입력하셨습니다.\n관리자에게 문의해 주세요.',
    'default': '이메일 또는 비밀번호를 다시 한번 확인해 주세요.'
  },
  PASSWORD: {
    'default': '현재 비밀번호를 다시 한번 확인해 주세요.'
  },
  COMMON: {
    'default': '나중에 다시 시도해 주세요.'
    // 'default': '서비스를 이용하는 데 문제가 있습니다.\n문제가 지속되면 나중에 다시 시도해 주세요.'
  }
};

// ----------------------------------------------------------------------

const message = (request, code) => {
  const answer = MESSAGE[request];
  const codes = Object.keys(answer);

  return code && codes.includes(code) ? answer[code] : answer.default;
};

export default message;