import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, AffiliatedTitle, SearchWrapper, AddButton } from '../../../styles';
// apis
import { getChannels, deleteChannels } from '../../../apis';

// ----------------------------------------------------------------------

const channel = Array.from({length: 16}, (_, i) => i + 1);

// ----------------------------------------------------------------------

export default function ChannelPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const sensor = state?.sensor;

  const [ data, setData ] = useState([]);
  const [ channels, setChannels ] = useState([]);
  
  const [ dialog, setDialog ] = useState({
    delete: { visible: false },
    form: { visible: false }
  });

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getChannels(setData, sensor.id)
    )();
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const current = data.map((item) => item.channel)
    const set = channel.filter((item) => !current.includes(item));
    setChannels(set);
  }, [data]);

  const handleDelete = async () => {
    await deleteChannels(dialog.delete.id)
      .then(() => {
        handleReset();
        setDialog({ ...dialog,
          delete: { visible: false }
        });
      })
      .catch(() => {
        setMessage('채널을 삭제할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  const handleReset = async (error) => {
    await getChannels(setData, sensor.id);
    if ( error ) {
      setDialog({ ...dialog,
        form: { visible: true }
      });
    }
  };

  const cellRender = ({data}) => {
    return <TableRow row={data} dialog={dialog} setDialog={setDialog} />;
  };

  if ( !state ) return;

  return (
    <Content>
      <Breadcrumbs
        heading='부식 전위 채널 관리'
        links={[
          { name: '시스템 관리' },
          { name: '부식 전위 그룹 관리', link: '/system/potential-group' },
          { name: '부식 전위 센서 관리', link: '/system/potential-group/potential-sensor', state: state },
          { name: '부식 전위 채널 관리' }
        ]}
      />

      <Box>
        <AffiliatedTitle>
          <p>No.{ sensor.number }</p>
          <span>{ sensor.serialNumber }</span>
        </AffiliatedTitle>

        <SearchWrapper>
          <div />

          <AddButton onClick={() => {
            setDialog({ ...dialog,
              form: { visible: true }
            });
          }}>
            추가
          </AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={data}
          noDataText=''
          columnAutoWidth={true}
        >
          <Column caption='채널' dataField='channel' alignment='center' />
          <Column caption='설명' dataField='description' alignment='center' />
          <Column caption='' dataField='id' cellRender={cellRender} alignment='center' />
        </DataGrid>

        { dialog.delete.visible &&
          <Dialog
            title='부식 전위 채널 삭제'
            icon='delete'
            message={message}
            actions={
              <div>
                <button onClick={() => handleDelete()}>
                  삭제
                </button>
                <button onClick={() => {
                  setDialog({ ...dialog,
                    delete: { visible: false }
                  });
                  setMessage(null);
                }}>
                  취소
                </button>
              </div>
            }
          >
            <p>선택하신 부식 전위 채널을 삭제하시겠습니까?</p>
          </Dialog>
        }

        { dialog.form.visible &&
          <Form dialog={dialog} setDialog={setDialog} sensor={sensor.id} channels={channels} handleReset={handleReset} />
        }
        
      </Box>
    </Content>
  );
}