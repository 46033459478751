import PuffLoader from 'react-spinners/PuffLoader';
//
import { Container } from './styles';

// ----------------------------------------------------------------------

export default function Loaders() {
  return (
    <Container>
      <PuffLoader color='#00A451' size={180} />
    </Container>
  );
};