import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialSensorList = async (setData, groupId) => {
  return await axios.get(`groups/potential-groups/${groupId}/sensors`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPotentialSensorList;