import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const createCompanies = async (name) => {
  return await axios.post('companies ', {name})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createCompanies;