import { useEffect } from 'react';
// prop-types
import PropTypes from 'prop-types';
// @pagination
import ReactPagination from 'react-js-pagination';
// styles
import { Wrapper } from './styles';

// ----------------------------------------------------------------------

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  handlePage: PropTypes.func,
  size: PropTypes.number,
  sizes: PropTypes.arrayOf(PropTypes.number),
  handleSize: PropTypes.func
};

// ----------------------------------------------------------------------

export default function Pagination({ page, pages, handlePage, size = 10, sizes, handleSize, type }) {

  useEffect(() => {
    if ( window.scrollY === 0 ) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [ page, size ]);

  return (
    <Wrapper $dialog={type === 'dialog' && true}>

      <ReactPagination
        activePage={page + 1}
        itemsCountPerPage={size}
        totalItemsCount={size * pages}
        pageRangeDisplayed={type === 'dialog' ? 3 : 5}
        onChange={handlePage}
        itemClassFirst='arrow'
        itemClassPrev='arrow double'
        itemClassNext='arrow rotate'
        itemClassLast='arrow double rotate'
        prevPageText=''
        firstPageText=''
        lastPageText=''
        nextPageText=''
      />

      { sizes &&
        <select defaultValue={size} onChange={handleSize}>
          { sizes.map((option) => (
            <option key={option} value={option}>{ option }</option>
          ))}
        </select>
      }
      
    </Wrapper>
  );
}