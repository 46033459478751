import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const deletePotentialSensors = async (sensorId) => {
  return await axios.delete(`potential-sensors/${sensorId}`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default deletePotentialSensors;