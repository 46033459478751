import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getPotentialSensors = async (setData, groupId, { number, serialNumber }, page, size) => {
  return await axios.get(`potential-sensors/groups/${groupId}/search-page?number=${number}${encodeURIComponent(serialNumber)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPotentialSensors;