import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-bottom: 1px solid #DCE0E4;

  th, td {
    text-align: center;
    border: none;
    font-size: 14px;
    font-weight: 500;
    white-space : nowrap;
    padding: 0 15px;
  }

  th {
    background-color: #F4F6F8;
    height: 55px;
    position: sticky;
  }

  th p {
    color: #637381;
    position: relative;
    display: inline-block;
  }

  td {
    color: #444444;
    height: 65px;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px double #e5e5e5;
  }

  &.soil {
    td:last-child {
      border-right: none;
    }
    tr:nth-child(3n+1) td:first-child, tr:nth-child(3n) td {
      border-bottom: 4px double #e5e5e5;
    }
    tr:nth-last-child(3) td:first-child, tr:last-child td {
      border-bottom: none;
    }
  }

  &.potential {
    tr:nth-child(1) td:nth-child(1), tr:nth-child(1) td:nth-child(2), tr:nth-child(1) td:nth-child(n+6), tr:nth-child(4) td,
    tr:nth-child(5) td:nth-child(1), tr:nth-child(5) td:last-child, tr:nth-child(21) td:nth-child(1), tr:nth-child(21) td:nth-child(n+6), tr:nth-child(28) td, tr:nth-child(25) td:last-child,
    tr:nth-child(29) td:nth-child(1), tr:nth-child(29) td:nth-child(2), tr:nth-child(29) td:nth-child(n+7), tr:nth-child(33) td:last-child, tr:nth-child(36) td {
      border-bottom: 4px double #e5e5e5;
    }

    tr:nth-child(1) td:last-child, tr:nth-child(5) td:last-child, tr:nth-child(29) td:last-child, tr:nth-child(37) td:last-child {
      border-right: none;
    }
    
    tr:nth-child(37) td:first-child, tr:nth-child(37) td:last-child, tr:nth-child(61) td:first-child, tr:nth-child(61) td:nth-child(n+6), tr:nth-child(69) td:last-child, tr:last-child td {
      border-bottom: none;
    }
  }
`;