import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// sections
import TableRow from './TableRow';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Content, Box, SearchWrapper, AddButton } from '../../../styles';
// apis
import { getSoilGroups, deleteSoilGroups } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [ 20, 30 ];

// ----------------------------------------------------------------------

export default function SoilGroupPage() {
  const navigate  = useNavigate();

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);
  
  const [ dialog, setDialog ] = useState({ visible: false });
  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    ( async () =>
      await getSoilGroups(setData, keyword, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value - 1);
    getSoilGroups(setData, keyword, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize( Number(event.target.value) );
    getSoilGroups(setData, keyword, 0, Number(event.target.value));
  };

  const handleSearch = () => {
    setPage(0);
    getSoilGroups(setData, keyword, 0, size);
  };

  const handleDelete = async () => {
    await deleteSoilGroups(dialog.id)
      .then(() => {
        handleSearch();
        setDialog({ visible: false });
      })
      .catch(() => {
        setMessage('토양 그룹을 삭제할 수 없습니다. 나중에 다시 시도해 주세요.');
      });
  };

  const cellRender = ({data}) => {
    return <TableRow row={data} setDialog={setDialog} />;
  };

  return (
    <Content>
      <Breadcrumbs
        heading='토양 그룹 관리'
        links={[
          { name: '시스템 관리' },
          { name: '토양 그룹 관리' }
        ]}
      />

      <Box>
        <SearchWrapper>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            handleSearch={handleSearch}
            handleInitialize={handleSearch}
            placeholder='그룹명을 입력하세요.'
          />
          <AddButton onClick={() => navigate('create')}>등록</AddButton>
        </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
        >
          <Column caption='그룹' dataField='name' alignment='center' />
          <Column caption='설명' dataField='description' alignment='center' />
          <Column caption='위도' dataField='latitude' alignment='center' />
          <Column caption='경도' dataField='longitude' alignment='center' />
          <Column caption='줌 레벨' dataField='zoom' alignment='center' />
          <Column caption='격자 X' dataField='gridX' alignment='center' />
          <Column caption='격자 Y' dataField='gridY' alignment='center' />
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column caption='' dataField='id' cellRender={cellRender} alignment='center' />
        </DataGrid>

        <Pagination page={page} pages={pages} handlePage={handlePage} size={size} sizes={sizes} handleSize={handleSize} />

        { dialog.visible &&
          <Dialog
            title='토양 그룹 삭제'
            icon='delete'
            message={ message }
            actions={
              <div>
                <button onClick={() => handleDelete()}>
                  삭제
                </button>
                <button onClick={() => {
                  setDialog({visible: false});
                  setMessage(null);
                }}>
                  취소
                </button>
              </div>
            }
          >
            <span>
              선택하신 토양 그룹에 소속된<br />
              모든 하위 토양 센서도 삭제됩니다.
            </span>
            <p>삭제하시겠습니까?</p>
          </Dialog>
        }
        
      </Box>
    </Content>
  );
}