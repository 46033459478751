import { createGlobalStyle } from 'styled-components';

// ----------------------------------------------------------------------

const GlobalStyles = createGlobalStyle`
  *,
  :after,
  :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
    font-size: 14px;
    color: #444444;
    font-family: Noto Sans KR, DXIcons, sans-serif;
  }

  ul,
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #444444;
  }

  input,
  button,
  select,
  textarea {
    color: #444444;
    background-color: #FFFFFF;
    font-size: inherit;
    font-family: inherit;
  }

  input:focus,
  select:focus {
    outline: none;
  }
  
  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  input::placeholder {
    letter-spacing: .5px;
    color: #919EAB;
  }

  input:disabled {
    background-color: #FBFBFC;
    color: #637381;
  }
  
  button:disabled {
    cursor: default;
    color: #919EAB;
  }

  form {
    > div {
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    > button:not([type='button']) {
      margin-top: 50px;
    }
  }

  input[type='radio'] {
    accent-color: ${(props) => props.theme.colors.primary.main };
    width: 1em;
    height: 1em;
    vertical-align: middle;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #919EAB;
    border-radius: 3px;
  }

  input[type='checkbox']:disabled + label:before {
    background-color: #FBFBFC;
  }

  input[type='checkbox']:checked + label:before {
    border-color: ${(props) => props.theme.colors.primary.main };
    background-color: ${(props) => props.theme.colors.primary.main };
    background-image: url(/assets/inputs/checked.png);
    background-size: 10px 7px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  input[type='checkbox'] + label > span {
    display: inline-block;
    vertical-align: middle;
    padding-left: 8px;
    font-size: 13px;
    font-weight: 500;
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/assets/inputs/select.svg);
    background-size: 12px 7px;
    background-position: right 8px center;
    background-repeat: no-repeat;
    height: 34px;
    padding: 0 30px 0 10px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.border };
  }
  select::-ms-expand {
    display: none;
  }
`;

export default GlobalStyles;