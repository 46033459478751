import { useState, useEffect, useRef } from 'react';
// prop-types
import PropTypes from 'prop-types';
// styles
import { ButtonContainer, RippleStyled } from './styles';

// ----------------------------------------------------------------------

Button.propTypes = {
  label: PropTypes.string
};

// ----------------------------------------------------------------------

const useDebounce = ( value ) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), 500);
    return () => clearTimeout(timer);
  }, [ value ]);

  return debouncedValue;
}

const useRipple = ( ref ) => {
  const [ ripples, setRipples ] = useState([]);

  useEffect(() => {
    if ( ref.current ) {
      const elem = ref.current;

      const clickHandler = (e) => {
        let rect = elem.getBoundingClientRect();
        let left = e.clientX - rect.left;
        let top = e.clientY - rect.top;

        const width = elem.clientWidth;
        const height = elem.clientHeight;
        const maxSize = Math.max(width, height);

        setRipples([
          ...ripples,
          {
            left: left - maxSize / 2,
            top: top - maxSize / 2,
            width: maxSize,
            height: maxSize
          }
        ]);
      };

      elem.addEventListener('click', clickHandler);

      return () => {
        elem.removeEventListener('click', clickHandler);
      }
    }
  }, [ ref, ripples ]);

  const _debounced = useDebounce(ripples);

  useEffect(() => {
    if ( _debounced.length ) setRipples([]);
  }, [ _debounced.length ]);

  return ripples.map((style, index) => (
    <RippleStyled key={index} style={{ ...style, backgroundColor: '#FFFFFF' }} />
  ));
}

export default function Button({ label, ...props }) {
  const buttonRef = useRef(null);
  const ripples = useRipple(buttonRef);

  return (
    <ButtonContainer
      ref={buttonRef}
      { ...props }
    >
      <span>{ label }</span>
      { ripples }
    </ButtonContainer>
  );
};