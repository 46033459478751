import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import Tab from '../../../components/tab';
import TextField from '../../../components/text-field';
import Button from '../../../components/button';
import { Dialog } from '../../../components/dialog';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// react-daum-postcode
import DaumPostcode from 'react-daum-postcode';
// utils
import { REGEXP_SPECIAL } from '../../../utils/regexp';
// styles
import { Content, FormContent, Box, AffiliatedTitle, Label, WithButton, FieldWrapper, Message, ButtonWrapper } from '../../../styles';
// apis
import { checkSoilNumber, createSoilSensors, updateSoilSensors } from '../../../apis';

// ----------------------------------------------------------------------

const tabs = [
  { value: 'coordinate', label: '위도 / 경도'},
  { value: 'address', label: '주소'}
];

const schema = yup.object().shape({
  latitude: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(-90, '-90 ~ 90 범위 내에서 입력해주세요.')
    .max(90, '-90 ~ 90 범위 내에서 입력해주세요.'),
  longitude: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(-180, '-180 ~ 180 범위 내에서 입력해주세요.')
    .max(180, '-180 ~ 180 범위 내에서 입력해주세요.'),
  address: yup.string()
    .required(),
  number: yup.number()
    .typeError('숫자만 입력해 주세요.')
    .min(1, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.')
    .max(9223372036854775807, '1 ~ 9,223,372,036,854,775,807 범위 내에서 입력해주세요.'),
});

// ----------------------------------------------------------------------

export default function SoilSensoFormPage() {
  const navigate  = useNavigate();

  const state = useLocation().state;
  const group = useLocation().state?.group;
  const sensor = useLocation().state?.sensor;

  const pathname = useLocation().pathname.split('/')[4];
  const create = pathname === 'create' ? true : false;
  const title = create ? '등록' : '수정';

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    getFieldState,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      latitude: sensor?.latitude || '',
      longitude: sensor?.longitude || '',
      address: sensor?.address || '',
      number: sensor?.number || 1
    }
  });

  const [ naver, setNaver ] = useState(null);

  const [ tab, setTab ] = useState('coordinate');

  const [ detail, setDetail ] = useState('');
  
  const [ visible, setVisible ] = useState(false);

  const [ auto, setAuto ] = useState(create);
  const [ checked, setChecked ] = useState({ result: null, number: null });
  const [ message, setMessage ] = useState({ detail: null, checked: null, error: null });

  const [ error, setError ] = useState(false);

  useEffect( () => {
    if ( !state || (!create && !sensor) ) return navigate(-1);
    setNaver( window.naver );
    // eslint-disable-next-line
  }, []);

  useEffect( () => {
    if (
      (tab === 'address' && !getFieldState('address').error) ||
      (tab === 'coordinate' && ( !getFieldState('latitude').error || !getFieldState('longitude').error) )
    ) return setError(false);
    setError(true);
    // eslint-disable-next-line
  }, [getFieldState('address').error, getFieldState('latitude').error, getFieldState('longitude').error, tab]);

  const handleTab = (value) => setTab(value);

  const handleComplete = (data) => {
    if ( data.userSelectedType === 'R' ) {
      setValue('address', data.roadAddress);
    } else {
      setValue('address', data.jibunAddress);
    }
    setVisible(false);
    clearErrors(['address']);
  };

  const geocode = (address) => {
    naver.maps.Service.geocode(
      { query: address },
      function ( status, response ) {
        if ( status === naver.maps.Service.Status.ERROR ) return setMessage({ ...message, error: `토양 센서를 ${title}할 수 없습니다. 나중에 다시 시도해 주세요.` });
        if ( response.v2.meta.totalCount === 0 )  return setMessage({ ...message, error: '유효한 주소를 입력해 주세요.' });
        const result = response.v2.addresses[0];
        onFinalSubmit({ latitude: result.y, longitude: result.x, address: address });
      }
    )
  };

  const reverseGeocode = (lat, lng) => {
    naver.maps.Service.reverseGeocode(
      {
        coords: new naver.maps.LatLng( lat, lng ),
        orders: [
            naver.maps.Service.OrderType.ADDR,
            naver.maps.Service.OrderType.ROAD_ADDR
        ].join(',')
      },
      function (status, response) {
        if ( status === naver.maps.Service.Status.ERROR ) return setMessage({ ...message, error: `토양 센서를 ${title}할 수 없습니다. 나중에 다시 시도해 주세요.` });
        if ( response.v2.status.code === 3 ) return setMessage({ ...message, error: '유효한 위도, 경도를 입력해 주세요.' });
        const result = response.v2.address.jibunAddress.replace( "  ", " " );
        onFinalSubmit({ latitude: lat, longitude: lng, address: result });
      }
    )
  };

  const handleChecked = async () => {
    const value = getValues('number');

    await checkSoilNumber(group.id, value)
      .then((response) => {
        setChecked({ result: !response, number: value });
        setMessage({ ...message, checked: !response ? '사용하실 수 있는 장비 번호입니다.' : '이미 등록된 장비 번호입니다.' });
      })
      .catch((error) => {
        setMessage({ ...message, checked: error.message });
      });
  };

  const onError = (error) => {

    if ( !REGEXP_SPECIAL.test(detail) ) return setMessage({ ...message, detail: '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.' });
    if ( detail.replace(/ /g, '').length !== 0 && (detail.length < 2 || detail.length > 50) ) return setMessage({ ...message, detail: '2 - 50자 이내로 입력해주세요.' });
    setMessage({ ...message, detail: null });

    if ( error.number ) return;

    if ( tab === 'coordinate' && !error.latitude && !error.longitude ) {
      reverseGeocode(getValues('latitude'), getValues('longitude'));
    } else if ( tab === 'address' && !error.address ) {
      geocode(getValues('address'));
    }
  };

  const onSubmit = (data) => {
    if ( !REGEXP_SPECIAL.test(detail) ) return setMessage({ ...message, detail: '한글·영문·숫자·특수 문자!@&()-_+[]{}. 를 사용해 주세요.' });
    if ( detail.replace(/ /g, '').length !== 0 && (detail.length < 2 || detail.length > 50) ) return setMessage({ ...message, detail: '2 - 50자 이내로 입력해주세요.' });
    setMessage({ ...message, detail: null });

    if ( tab === 'coordinate' ) {
      reverseGeocode(data.latitude, data.longitude);
    } else {
      geocode(data.address);
    }
  };

  const onFinalSubmit = async (data) => {
    if ( !auto
      && ( checked.number !== getValues('number') || checked.result === null )
      && ( create || (!create && Number(getValues('number')) !== sensor.number) ) 
    ) {
      setChecked({ result: null, number: null });
      setMessage({ ...message, checked: '중복 체크 후 진행하실 수 있습니다.' });
    } else if ( checked.result || (!create && Number(getValues('number')) === sensor.number) || ( create && auto ) ) {
      const result = { number: getValues('number'), autoNumber: auto, latitude: data.latitude, longitude: data.longitude, address: data.address, detailAddress: detail };
      if ( detail.replace(/ /g, '').length === 0 ) delete result.detailAddress;
      if ( create ) {
        await createSoilSensors({ ...result, groupId: group.id})
          .then(() => {
            navigate('/system/soil-group/soil-sensor', {state: { ...group, submit: true }});
          })
          .catch(() => {
            setMessage({ ...message, error: '토양 센서를 등록할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      } else {
        delete result.autoNumber;
        await updateSoilSensors(sensor.id, result)
          .then(() => {
            navigate('/system/soil-group/soil-sensor', {state: { ...group, submit: true }});
          })
          .catch(() => {
            setMessage({ ...message, error: '토양 센서를 수정할 수 없습니다. 나중에 다시 시도해 주세요.' });
          });
      }
    }
  };

  return (
    <Content>
      <Breadcrumbs
        heading={`토양 센서 ${title}`}
        links={[
          { name: '시스템 관리' },
          { name: '토양 그룹 관리', link: '/system/soil-group' },
          { name: '토양 센서 관리', link: '/system/soil-group/soil-sensor', state: state },
          { name: `토양 센서 ${title}` }
        ]}
      />

      <FormContent>
        <Box>
          <AffiliatedTitle $form>
            <p>{ group?.name }</p>
            <span>{ group?.description }</span>
          </AffiliatedTitle>

          <Tab tabs={tabs} tab={tab} handleTab={handleTab} />

          <form onSubmit={ handleSubmit(onSubmit, onError) }>
            { tab === 'coordinate' ? (
              <>
                <TextField
                  type='text'
                  label='위도'
                  name='latitude'
                  control={control}
                />

                <TextField
                  type='text'
                  label='경도'
                  name='longitude'
                  control={control}
                />
              </>
            ) : (
              <>
                <WithButton>
                  <Label>주소</Label>
                  <div>
                    <TextField
                      type='text'
                      name='address'
                      control={control}
                      readOnly
                    />
                    <button type='button' onClick={() => setVisible(true)}>검색</button>
                  </div>
                </WithButton>

                <FieldWrapper $error={message.detail && true}>
                  <Label>상세 주소</Label>
                  <input type='text' value={detail} onChange={(event) => setDetail(event.target.value)} />
                </FieldWrapper>
                { message.detail &&
                  <Message>{ message.detail }</Message>
                }
              </>
            )}

            <WithButton>
              <Label>장비 번호</Label>
              <div>
                <TextField
                  type='number'
                  name='number'
                  control={control}
                  disabled={auto}
                />
                <button type='button' onClick={() => handleChecked()} disabled={auto}>중복 확인</button>
              </div>
            </WithButton>

            <FieldWrapper $flex>
              <Message $success={ checked.result }>{ message.checked }</Message>

              { create &&
                <div>
                  <input type='checkbox' id='checkbox' checked={auto} onChange={(event) => setAuto(event.target.checked)} />
                  <label htmlFor='checkbox'>
                    <span>AUTO</span>
                  </label>
                </div>
              }
            </FieldWrapper>

            { message.error &&
              <Message>{ message.error }</Message>
            }

            <ButtonWrapper>
              <Button label={title} disabled={error} />
              <button type='button' onClick={() => navigate(-1)}>취소</button>
            </ButtonWrapper>
          </form>

          { visible &&
            <Dialog
              actions={
                <button onClick={() => setVisible(false)}>
                  취소
                </button>
              }
            >
              <DaumPostcode onComplete={handleComplete} />
            </Dialog>
          }

        </Box>
      </FormContent>
    </Content>
  );
}