import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  input {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.border };
    padding: 0 10px;
  }

  div {
    height: 45px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.border };
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: calc(100% - 34px);
      height: 100%;
      border: none;
      padding: 0;
    }
  }
`;

export const Label = styled.p`
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 3px;
`;

export const Button = styled.button`
  width: 24px;
  height: 24px;
  background-image: url(/assets/password/${(props) => props.name }.png);
  background-size: 100% auto;
  background-position: right center;
  background-repeat: no-repeat;

  &:active {
    box-shadow: none;
  }
`;

export const Message = styled.p`
  font-size: 13px;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.error };
`;