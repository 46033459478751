import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const getRegisteredSoils = async (setData, companyId, name, page, size) => {
  return await axios.get(`company-soils/companies/${companyId}/registered/search-page?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getRegisteredSoils;