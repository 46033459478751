import { ThemeProvider as Theme } from 'styled-components';
import GlobalStyles from './GlobalStyles';
import theme from './theme';
//
import { locale } from 'devextreme/localization';
import 'devextreme/dist/css/dx.material.blue.light.css';

// ----------------------------------------------------------------------

import './font.css';
import './dx-styles.css';

// ----------------------------------------------------------------------

export * from './styles';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  locale('ko');

  return (
    <Theme theme={{ ...theme }}>
      <GlobalStyles />
      { children }
    </Theme>
  );
}