import axios from '../../_utils/axios';

// ----------------------------------------------------------------------

const enableSoilSensors = async (sensorId) => {
  return await axios.put(`soil-sensors/${sensorId}/enable `)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default enableSoilSensors;